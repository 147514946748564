import { DateTime, Duration } from 'luxon';
import { REG_EXP } from 'app/app.settings';

/**
 * @method dateReviver
 * @description This function parses a string with ISO date/duration pattern and returns the
 * deserialized object (DateTime or Duration). If not, returns the original object. This function
 * is meant to be used with the JSON.parse method (as its second parameter).
 * @param key > the key (name) of the property whose value to parse.
 * @param value > the value to parse and potentially be transformed into a date/duration.
 * @return the date/duration object parsed, or the original object if
 * the object does not follow an ISO date/duration pattern.
 */
export function dateReviver(key: string, value: object): object | DateTime | Duration
{
  if (typeof value === 'string')
  {
    if (REG_EXP.iso8601Date.test(value))
    {
      return DateTime.fromISO(value);
    }
    if (REG_EXP.iso8601Duration.test(value))
    {
      return Duration.fromISO(value);
    }
  }
  return value;
}

/**
 * @method dateReplacer
 * @description This function checks if the property to serialize is of DateTime or Duration
 * types and serlializes them into ISO strings. If not, returns the original object. This
 * function is meant to be used with the JSON.stringify method (as its second parameter).
 * @param key > the key (name) of the property to serialize.
 * @param value > the value to serialize and potentially be transformed into a date/duration ISO
 * string.
 * @return the date/duration object serialized, or the original object if the
 * object is not a Luxon DateTime or Duration object.
 */
export function dateReplacer(key: string, value: object):  object | string
{
  if (DateTime.isDateTime(value))
  {
    return (value as DateTime).toISO();
  }
  if (Duration.isDuration(value))
  {
    return (value as Duration).toISO();
  }
  return value;
}

/**
 * @function minutesToHoursAndMinutes
 * @description Transforms minutes to an hours and minutes representation (h min).
 * @param minutes > the amount of minutes to transform to human readable format (hours and minutes).
 * @returns {string} the minutes transformed to hours and minutes (e.g: 140 -> 2h 20min).
 */
export function minutesToHoursAndMinutes(minutes: number): string
{
    const duration = Duration.fromObject({ minutes: minutes });
    return duration.toMillis() === 0
      ? '0h'
      : duration.toFormat('h\'h\' mm\'min\'');
}

/**
 * @function reviveDates
 * @description Checks all the properties in the given object and, if some of them is a Date in
 * 8601 format, transforms it parsing the string into a Luxon DateTime.
 * @param object > the object to parse. it can be a composed object, a basic type, an array, or any
 * other thing.
 */
export function reviveDates(object: object): void
{
  if (!object || !(object instanceof Object))
  {
    return;
  }
  if (object instanceof Array)
  {
    for (const item of object)
    {
      reviveDates(item);
    }
  }
  for (const key of Object.keys(object))
  {
    const value = object[key];
    if (value instanceof Array)
    {
      for (const item of value)
      {
        reviveDates(item);
      }
    }
    if (value instanceof Object)
    {
      reviveDates(value);
    }
    object[key] = dateReviver(key, value);
  }
}
