import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ERRORS } from 'app/app.settings';
import { Message } from 'app/core/core.types';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService
{

  // -----------------------------------------------------------------------------------------------------
  // @ Properties
  // -----------------------------------------------------------------------------------------------------

  /** The Subject is Observer and Observable so its suitable for receive and send the errors */
  private _subject: Subject<Message> = new Subject<Message>();

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method get
   * This function returns the Observable of errors, for anyone who wants can subscribe to it.
   * @returns the Observable of error
   */
  get messenger$(): Observable<Message>
  {
    return this._subject.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method send
   * This function is used to send messages to the listeners subscripted to the service. From the
   * documentation: when you call next(value) on the Subject, it will loop through its list of
   * observers and forward that value along to their next methods
   * @param message > the message to send to the current listeners
   */
  public send(message: Message): void
  {
    this._subject.next(message);
  }

  /**
   * @method sendError
   * This function is used to send an error message to the listeners subscripted to the service.
   * @param message > the error message to send.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public sendError(error: string | HttpErrorResponse, ...params: unknown[]): void
  {
    if (typeof error === 'string')
    {
      this._subject.next({ Text: error, Type: 'error' } as Message);
    }
    else
    {
      if (!navigator.onLine)
      {
        this._subject.next({ Text: ERRORS.connection, Type: 'error' } as Message);
      }
      else if (Math.floor(error.status/500))
      {
        this._subject.next({ Text: ERRORS.internal, Type: 'error' } as Message);
      }
      else if (error.status === 401)
      {
        if (error.error?.Message === 'E_BadCredentials')
        {
          this._subject.next({ Text: ERRORS.badCredentials, Type: 'error' } as Message);
        }
        else if (error.error?.Message === 'E_PasswordExpired')
        {
          this._subject.next({ Text: ERRORS.expiredPassword, Type: 'error' } as Message);
        }
        else
        {
          this._subject.next({ Text: ERRORS.expiredSession, Type: 'error' } as Message);
        }
      }
      else if (error.status === 403)
      {
        if (error.error?.Message === 'E_WorkCenterClosed')
        {
          this._subject.next({ Text: ERRORS.closedWorkcenter, Type: 'error' } as Message);
        }
        else
        {
          this._subject.next({ Text: ERRORS.forbidden, Type: 'error' } as Message);
        }
      }
      else if (error.status === 404)
      {
        this._subject.next({ Text: ERRORS.notFound, Type: 'error' } as Message);
      }
      else
      {
        this._subject.next({ Text: ERRORS.unknown, Type: 'error' } as Message);
      }
    }
    console.log(error);
  }

  /**
   * @method sendInfo
   * This function is used to send an info message to the listeners subscripted to the service.
   * @param message > the info message to send.
   */
  public sendInfo(message: string, params?: { [key: string]: boolean | number | string | unknown }): void
  {
    this._subject.next({ Params: params, Text: message, Type: 'info' } as Message);
  }

  /**
   * @method sendSuccess
   * This function is used to send a success message to the listeners subscripted to the service.
   * @param message > the success message to send.
   */
  public sendSuccess(message: string, params?: { [key: string]: boolean | number | string | unknown }): void
  {
    this._subject.next({ Params: params, Text: message, Type: 'success' } as Message);
  }

  /**
   * @method sendWarning
   * This function is used to send a warning message to the listeners subscripted to the service.
   * @param message > the success message to send.
   */
  public sendWarning(message: string, params?: { [key: string]: boolean | number | string | unknown }): void
  {
    this._subject.next({ Params: params, Text: message, Type: 'warning' } as Message);
  }

}
