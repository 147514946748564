<!-- Navigation -->
<vertical-navigation
  class="bg-card dark:bg-gray-900 print:hidden"
  [appearance]="'thin'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.compact"
  [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container verticalNavigationContentHeader>
    <!-- Logo -->
    <a routerLink="/" class="no-underline cursor-default flex items-center justify-center h-20 text-primary-500">
      <svg
        class="w-8 fill-current"
        version="1.1"
        viewBox="0 0 1024 1024"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg">
        <path d="m888.31 1024h-752.6c-74.943 0-135.7-60.751-135.7-135.69v-752.61c0-74.943 60.761-135.69 135.7-135.69h752.6c74.943 0 135.69 60.751 135.69 135.69v752.61c0 74.943-60.751 135.69-135.69 135.69" />
        <path fill="#fff" d="m516.15 254.83c-46.074 0-88.619 11.328-127.6 33.963-39.008 22.636-70.008 54.448-93.049 95.388-23.032 40.98-34.548 84.279-34.548 129.96 0 45.311 11.625 88.619 34.845 129.97 23.24 41.347 54.448 73.536 93.624 96.568 39.186 23.052 81.226 34.568 126.13 34.568 45.281 0 88.193-11.417 128.77-34.261 40.544-22.854 71.762-53.754 93.634-92.742 21.853-38.978 32.774-82.901 32.774-131.71 0-74.428-24.509-136.67-73.546-186.68-49.027-50.018-109.38-75.012-181.03-75.012m330.88-63.278v643.42h-81.633v-110.59c-34.687 42.189-73.605 73.922-116.79 95.2-43.17 21.298-90.393 31.961-141.64 31.961-91.067 0-168.84-33.032-233.29-99.075-64.468-66.014-96.697-146.35-96.697-240.99 0-92.623 32.526-171.88 97.579-237.74 65.062-65.825 143.31-98.748 234.78-98.748 52.823 0 100.63 11.229 143.4 33.696 42.774 22.497 80.334 56.192 112.65 101.14v-118.26z" />
      </svg>
    </a>
  </ng-container>
</vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

  <!-- Header -->
  <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button
      class="mr-2"
      mat-icon-button
      (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
      <languages></languages>
      <fullscreen class="hidden md:block"></fullscreen>
      <search [appearance]="'bar'"></search>
      <!-- <notifications></notifications> -->
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
          Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
    <span class="font-medium text-secondary">Asisto &copy; {{currentYear}}</span>
  </div>

</div>
