import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AppConfig } from 'app/app.settings';
import { ConfigService } from 'app/core/config/config.service';
import {
  CareType,
  DataSet,
  EvolutionIssue,
  Pagination,
  SocialIssue,
  Task,
  TaskOccurrence,
  TechnicalIssue,
  User,
  UserContract,
  UserPersonalContact
} from 'app/core/core.types';
import { SessionStorageService } from 'app/core/storage/session-storage.service';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy
{

  // -----------------------------------------------------------------------------------------------------
  // @ Properties
  // -----------------------------------------------------------------------------------------------------

  /** Contains the (current) configuration */
  private _config: AppConfig;
  /** Used to free the subscriptions made for the current service */
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * @constructor
   * Subscribes to the config service in order to know the default API uri.
   * @param _httpClient > this service allows to make HTTP calls to the backend.
   * @param _sessionStorageService > this object allows to access to the session storage stored
   * properties.
   */
  constructor(
    private _configService: ConfigService,
    private _httpClient: HttpClient,
    private _sessionStorageService: SessionStorageService
  )
  {
    this._configService.config$
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((config: AppConfig) => this._config = config);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method ngOnDestroy
   * Frees the subscriptions made in the service.
   */
  public ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method getPage
   * Gets the list of users of the specified workcenter, filtered and paged.
   */
  public getPage(workCenterId: number, pagination: Pagination): Observable<DataSet<User>>
  {
    return this._httpClient.post<DataSet<User>>(`${this._config.webApi}/users/workcenter/${workCenterId}/page`, pagination);
  }

  /**
   * @method getOne
   * Get the user whose identifier is passed by parameter (and stores it in the session storage).
   * @param id > the identifier of the user to load.
   * @returns {Observable<User>} an observable for the user object requested.
   */
  public getOne(id: number): Observable<User>
  {
    return this._httpClient.get<User>(`${this._config.webApi}/users/${id}`).pipe(
      tap((user) => {
        this._sessionStorageService.user = user;
      })
    );
  }

  /**
   * @method getCompletedTasks
   * Gets the list of tasks that at least once have been completed for the specified user, under
   * public (compensation) contracts based on official body contracts with the specified official
   * body.
   */
  public getCompletedTasks(userId: number, officialBodyId: number): Observable<Task[]>
  {
    return this._httpClient.get<Task[]>(`${this._config.webApi}/users/${userId}/completed-tasks/official-body/${officialBodyId}`);
  }

  /**
   * @method getEvolutionIssueDocument
   * GET: gets the document attached to the evolution issue whose id is passed by parameter.
   * @param userId > the id of the user whose evolution issue document want to be retrieved from
   * the database.
   * @returns an object of type binary object with the data of the file attached
   * to the evolution issue.
   */
  public getEvolutionIssueDocument(userId: number, id: number): Observable<HttpResponse<Blob>>
  {
    return this._httpClient.get(`${this._config.webApi}/users/${userId}/evolution-issue/${id}/document`, { responseType: 'blob', observe: 'response' });
  }

  /**
   * @method getEvolutionIssues
   * Gets the list of evolution issues of the specified user.
   */
  public getEvolutionIssues(userId: number, pagination: Pagination): Observable<DataSet<EvolutionIssue>>
  {
    return this._httpClient.post<DataSet<EvolutionIssue>>(`${this._config.webApi}/users/${userId}/evolution-issues/page`, pagination);
  }

  /**
   * @method getPersonalContacts
   * Gets the list of personal contacts registered for the user specified, filtered and paged.
   */
  public getPersonalContacts(userId: number, pagination: Pagination): Observable<DataSet<UserPersonalContact>>
  {
    return this._httpClient.post<DataSet<UserPersonalContact>>(`${this._config.webApi}/users/${userId}/personal-contacts/page`, pagination);
  }

  /**
   * @method getPublicCompletedTasks
   * Gets the list of completed tasks under public contracts of the specified user and task.
   */
  public getPublicCompletedTasks(userId: number, taskId: number, pagination: Pagination): Observable<DataSet<TaskOccurrence>>
  {
    return this._httpClient.post<DataSet<TaskOccurrence>>(`${this._config.webApi}/users/${userId}/completed-tasks/task/${taskId}/public/page`, pagination);
  }

  /**
   * @method getPublicContracts
   * Gets the list of public contracts of the specified user.
   */
  public getPublicContracts(userId: number, pagination: Pagination): Observable<DataSet<UserContract>>
  {
    return this._httpClient.post<DataSet<UserContract>>(`${this._config.webApi}/users/${userId}/contracts/public/page`, pagination);
  }

  /**
   * @method getPublicContractsMonthlyHours
   * Gets the list of contracts with the service time granted to the specified user for each of them.
   */
  public getPublicContractsMonthlyHours(userId: number): Observable<{ Contract: string; MonthlyMinutes: number }[]>
  {
    return this._httpClient.get<{ Contract: string; MonthlyMinutes: number }[]>(`${this._config.webApi}/users/${userId}/monthly-hours/public`);
  }

  /**
   * @method getPublicCopayments
   * Gets the list of copayments (per contract) that the user has to pay for the service received under each of them.
   */
  public getPublicCopayments(userId: number): Observable<{ Contract: string; CopaymentType: number; Copayment: number }[]>
  {
    return this._httpClient.get<{ Contract: string; CopaymentType: number; Copayment: number }[]>(`${this._config.webApi}/users/${userId}/copayments/public`);
  }

  /**
   * @method getPublicTasks
   * Gets the list of tasks (per care type) that the user has granted under their compensation contracts.
   */
  public getPublicTasks(userId: number): Observable<CareType[]>
  {
    return this._httpClient.get<CareType[]>(`${this._config.webApi}/users/${userId}/tasks/public`);
  }

  /**
   * @method getSearch
   * Gets the list of users that comply with the search term.
   */
  public getSearch(searchTerm: string): Observable<{ Id: number; FullName: string; Photo: string }[]>
  {
    return this._httpClient.get<{ Id: number; FullName: string; Photo: string }[]>(`${this._config.webApi}/users/search/${searchTerm}`);
  }

  /**
   * @method getSocialIssueDocument
   * GET: gets the document attached to the social issue whose id is passed by parameter.
   * @param userId > the id of the user whose social issue document want to be retrieved from
   * the database.
   * @returns an object of type binary object with the data of the file attached
   * to the social  issue.
   */
  public getSocialIssueDocument(userId: number, id: number): Observable<HttpResponse<Blob>>
  {
    return this._httpClient.get(`${this._config.webApi}/users/${userId}/social-issue/${id}/document`, { responseType: 'blob', observe: 'response' });
  }

  /**
   * @method getSocialIssues
   * Gets the list of social issues of the specified user.
   */
  public getSocialIssues(userId: number, pagination: Pagination): Observable<DataSet<SocialIssue>>
  {
    return this._httpClient.post<DataSet<SocialIssue>>(`${this._config.webApi}/users/${userId}/social-issues/page`, pagination);
  }

  /**
   * @method getTechnicalIssue
   * Gets the full data of the technical issue specified.
   */
  public getTechnicalIssue(userId: number, technicalIssueId: number): Observable<TechnicalIssue>
  {
    return this._httpClient.get<TechnicalIssue>(`${this._config.webApi}/users/${userId}/technical-issues/${technicalIssueId}`);
  }

  /**
   * @method getTechnicalIssues
   * Gets the list of technical issues of the specified user.
   */
  public getTechnicalIssues(userId: number, pagination: Pagination): Observable<DataSet<TechnicalIssue>>
  {
    return this._httpClient.post<DataSet<TechnicalIssue>>(`${this._config.webApi}/users/${userId}/technical-issues/page`, pagination);
  }

}
