import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { Language } from 'app/app.settings';

/**
 * @function getLocalePaginatorIntl
 * @description This function allows to translate Angular Material tables by using the
 * TranslocoService.
 * @param translocoService > the {TranslocoService} used to get the translations.
 * @return the object with the translations used by the Material table.
 */
export function getLocalePaginatorIntl(translocoService: TranslocoService): MatPaginatorIntl
{
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = translocoService.translate('itemsPerPage');
  paginatorIntl.nextPageLabel = translocoService.translate('nextPage');
  paginatorIntl.previousPageLabel = translocoService.translate('previousPage');
  paginatorIntl.firstPageLabel = translocoService.translate('firstPage');
  paginatorIntl.lastPageLabel = translocoService.translate('lastPage');
  paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number): string =>
  translocoService.translate('rangePage', { pageNumber: 1 + page, total: Math.ceil(length/pageSize) });
  return paginatorIntl;
}

/**
 * This function generates the language string to be used for the first time the application is
 * launched. After that, the localstorage variable should be used.
 *
 * @returns the default language to use, based on the user browser configuration.
 */
export function getInitialLanguage(): Language
{
  const browserLanguage = window.navigator.language.substring(0, 2);
  return browserLanguage === 'es' || browserLanguage === 'ca'
    ? browserLanguage as Language
    : 'es';
}
