import { HttpHeaders } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ExtraOptions, PreloadAllModules } from '@angular/router';
import { Layout } from 'app/layout/layout.types';
import { environment } from 'environments/environment';
import { getInitialLanguage } from 'app/shared/lib/localization-util';

// Types
export type Credentials = { username: string; password: string };
export type Language    = 'es' | 'ca';
export type MessageType = 'info' | 'success' | 'error' | 'warning' | 'primary' | 'accent' | 'basic' ;
export type Planning    = 'base' | 'weekly';
export type Scheme      = 'auto' | 'dark' | 'light';
export type Screens     = { [key: string]: string };
export type Theme       = 'theme-default' | string;
export type Themes      = { id: string; name: string }[];

/**
 * AppConfig interface. Update this interface to strictly type your config object.
 * httpOptions: default options for HttpClient calls.
 */
export interface AppConfig
{
  authApi?: string;
  accessTokenOffset?: number;
  dateParameterFormat?: string;
  expirationPasswordDaysForWarning?: number;
  httpOptions?: { headers: HttpHeaders };
  language?: Language;
  layout?: Layout;
  scheme?: Scheme;
  screens?: Screens;
  signOutSeconds?: number;
  snackBarDuration?: number;
  snackBarPosition?: { horizontal: MatSnackBarHorizontalPosition; vertical: MatSnackBarVerticalPosition };
  theme?: Theme;
  webApi?: string;
}

/**
 * Defines an enumeration with the types of copayments used in the application.
 */
export enum CopaymentTypes
{
  none       = 0,
  decimal    = Math.pow(2, 0),
  percentage = Math.pow(2, 1),
  table      = Math.pow(2, 2)
}

/**
 * Defines an enumeration with the configuration properties of the visibility in the external web.
 */
export enum ExternalWebInfo
{
  viewUserFinancialData                    = 1,
  viewUserContractHours                    = 2,
  viewUserContractWeeklyHours              = 3,
  viewUserContractRegistrationRequestsPage = 4,
  viewUserRegistrationRequestsPage         = 5,
  viewUserDeregistrationRequestsPage       = 6,
  viewGenericRequestsPage                  = 7,
  viewUserContractsPage                    = 8,
  viewUserEvolutionIssuesPage              = 9,
  viewUserSocialIssuesPage                 = 10,
  viewUserTechnicalIssuesPage              = 11,
  viewInfoPage                             = 12,
  viewUserProfilePage                      = 13,
  viewUserPersonalContactsPage             = 14,
  viewBasePlanningPage                     = 15,
  viewWeeklyPlanningPage                   = 16,
  viewCheckedTasksPage                     = 17,
  viewTasksPage                            = 18,
  viewCarerTravelTime                      = 19
}

/**
 * Defines an enumeration used to indicate frequencies of a task. The values must match
 * with the transoloco identifiers in order to be used in the front end.
 */
export enum TaskFrequency
{
  occasionally = 0,
  weekly       = 1,
  biweekly     = 2,
  monthly      = 3,
  //yearly       = 4
}

/**
 * List of possible report formats that a report can be rendered in.
 */
export enum ReportFormat
{
  excelOpenXML = Math.pow(2, 0),
  pdf          = Math.pow(2, 1),
  wordOpenXml  = Math.pow(2, 2)
}

/**
 * List of possible sexes.
 */
export enum Sex { male   = 1, female = 2 }

/**
 * Default configuration for the entire application. This object is used by ConfigService to set
 * the default configuration.
 *
 * If you need to store global configuration for your app, you can use this object to set the
 * defaults. To access, update and reset the config, use ConfigService and its methods.
 */
export const APP_CONFIG: AppConfig =
{
  authApi                         : `${environment.protocol}://${environment.domain}/asisto-clece-authapi/api`,
  accessTokenOffset               : 1,
  dateParameterFormat             : 'YYYYMMDDTHHmmssZZ',
  expirationPasswordDaysForWarning: 30,
  httpOptions                     : { headers: new HttpHeaders({ 'Content-Type':  'application/json' }) },
  language                        : getInitialLanguage(),
  layout                          : 'classy',
  scheme                          : 'light',
  screens                         : { sm: '600px', md: '960px', lg: '1280px', xl: '1440px' },
  signOutSeconds                  : 5,
  snackBarDuration                : 5000,
  snackBarPosition                : { horizontal: 'center', vertical: 'bottom' },
  theme                           : 'theme-default',
  webApi                          : `${environment.protocol}://${environment.domain}/asisto-clece-webapi/api`,
};

/**
 * List of constants used for declaring typed empty variables.
 */
export const EMPTY =
{
  dateTime  : null,
  array     : [],
  fileArray : [] as Array<File>,
  number    : 0,
  nullArray : null as [],
  nullNumber: null as number,
  nullString: null as string,
  object    : null,
  string    : ''
};

/**
 * List of possible error identifiers used to display predefined alerts to the end user.
 */
export const ERRORS =
{
  badCredentials      : 'badCredentialsError',
  closedWorkcenter    : 'closedWorkcenterError',
  connection          : 'connectionError',
  downloadFile        : 'downloadFileError',
  emailNotFound       : 'emailNotFoundError',
  expiredPassword     : 'expiredPasswordError',
  expiredSession      : 'expiredSessionError',
  recoverEmailRequired: 'recoverEmailRequiredError',
  forbidden           : 'forbiddenError',
  internal            : 'internalServerError',
  notFound            : 'notFoundError',
  report              : 'reportError',
  unauthorized        : 'unauthorizedError',
  unknown             : 'undefinedError'
};

/**
 * List of possible HTTP headers used in the Web API calls.
 */
export const HTTP_HEADERS =
{
  textPlain              : { headers: new HttpHeaders({ 'Content-Type': 'text/plain' }) },
  applicationJson        : { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) },
  multipartFormData      : { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) },
  applicationOctectStream: { headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }) }
};

/**
 * List of date(time) formats used by the material date adapter (Luxon).
 */
export const LUXON_DATE_FORMATS = { display: { dateInput: 'DD-MM-YYYY' }, };

/**
 * Injection tokens
 */
export const MAT_DATE_RANGE_MAX_DAYS = new InjectionToken<number>('MAT_DATE_RANGE_MAX_DAYS'); // Number of days when selecting fixed (in number of days) date ranges

/**
 * Collection of reusable regular expressions used in custom pattern validators
 */
export const REG_EXP: { [key: string]: RegExp } =
{
  currency       : /^\d+(,\d{1,2})?$/i,
  date           : /^(19|20)[0-9]{2}-((0[0-9])|(1[0-2]))-(([0-2][0-9])|(3[0-1]))$/,
  email          : /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  fileName       : /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
  identification : /^((([X-Z])|([LM])){1}([-]?)((\d){7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]))$/,
  mobile         : /^((6\d)|(7[1-4]))\d{7}$/,
  //password       : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12}$/, // 1+ uppercase, 1+ lowercase, 1+ number, 1+ symbol
  password       : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{12}$/, // 1+ uppercase, 1+ lowercase, 1+ number
  postalCode     : /^(0[1-9]|5[0-2]|[1-4][0-9])\d{3}$/,
  // tslint:disable:max-line-length
  landline       : /^(8|9)\d{8}$/,
  iso8601Date    : /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$/, // eslint-disable-line max-len
  iso8601Duration: /^P([0-9]+(?:[,.][0-9]+)?Y)?([0-9]+(?:[,.][0-9]+)?M)?([0-9]+(?:[,.][0-9]+)?D)?(?:T([0-9]+(?:[,.][0-9]+)?H)?([0-9]+(?:[,.][0-9]+)?M)?([0-9]+(?:[,.][0-9]+)?S)?)?$/, // eslint-disable-line max-len
  // tslint:enable:max-line-length
  username       : /^\d+_[\p{L}\p{N}()][\p{L}\p{N}()\s.-]+[\p{L}\p{N}().](_\d+)*$/u
};

/**
 * Contains the list of file extensions corresponding to the {ReportFormat} enum.
 */
export const REPORT_FORMAT =
{
  excelOpenXML: '.xlsx',
  pdf         : '.pdf',
  wordOpenXml : '.docx'
};

/**
 * Defines the router navigation options.
 */
export const ROUTER_CONFIG: ExtraOptions =
{
  onSameUrlNavigation      : 'reload',
  preloadingStrategy       : PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

/**
 * List of possible success message identifiers used to display predefined notifications to the end
 * user.
 */
export const SUCCESS = { dataDeleted: 'dataDeleted', dataSaved  : 'dataSaved' };

/** The collection of routes that don't need to be intercepted*/
export const WHITELISTED_ROUTES_HTTP: (string | RegExp)[] = [`${APP_CONFIG.authApi}/login/external/token`];
