<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false">
    <!-- Navigation header hook -->
    <ng-container verticalNavigationContentHeader>
      <!-- Logo -->
      <a routerLink="/" class="no-underline cursor-default flex items-center h-20 pt-6 px-8">
        <svg
          class="w-24 fill-current text-primary"
          version="1.1"
          viewBox="0 0 400 120"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg">
          <path d="m44.002 42.585c-5.9768 0-11.496 1.4706-16.555 4.4061-5.0596 2.9367-9.0816 7.0645-12.071 12.377-2.9879 5.3156-4.4829 10.933-4.4829 16.859 0 5.8789 1.5084 11.498 4.5208 16.862 3.0157 5.3646 7.0645 9.5413 12.146 12.529 5.084 2.989 10.539 4.4829 16.364 4.4829 5.8744 0 11.443-1.4806 16.706-4.444 5.261-2.9645 9.3109-6.9732 12.147-12.032 2.8354-5.0573 4.2525-10.756 4.2525-17.088 0-9.656-3.1793-17.73-9.5414-24.219-6.3609-6.49-14.191-9.7328-23.488-9.7328m42.928-8.2099v83.475h-10.59v-14.347c-4.4996 5.4726-9.5503 9.5892-15.152 12.351-5.6006 2.763-11.728 4.1456-18.375 4.1456-11.816 0-21.907-4.2848-30.268-12.853-8.3636-8.5651-12.545-18.987-12.545-31.266 0-12.017 4.2202-22.3 12.661-30.844 8.4393-8.5406 18.591-12.812 30.459-12.812 6.8529 0 13.055 1.4561 18.605 4.3727 5.5494 2.9188 10.422 7.2893 14.615 13.12v-15.343z"/>
          <path d="m153.07 43.504-6.9053 7.1335c-5.7464-5.5727-11.361-8.3625-16.846-8.3625-3.4888 0-6.4756 1.1522-8.9636 3.4554-2.4869 2.3021-3.7304 4.9839-3.7304 8.0563 0 2.7096 1.0264 5.2911 3.0758 7.7469 2.0505 2.5081 6.3553 5.447 12.918 8.8233 7.9962 4.1445 13.43 8.1331 16.3 11.97 2.8187 3.8885 4.2291 8.2589 4.2291 13.119 0 6.8541-2.409 12.661-7.2248 17.415-4.8158 4.759-10.834 7.1379-18.057 7.1379-4.8169 0-9.4133-1.0486-13.793-3.1459-4.3805-2.0973-8.0051-4.9872-10.873-8.6697l6.7516-7.6734c5.4848 6.1895 11.305 9.2831 17.456 9.2831 4.307 0 7.9717-1.3793 10.996-4.1423 3.0257-2.7619 4.5386-6.0113 4.5386-9.7462 0-3.068-0.99967-5.8032-2.999-8.2099-1.9993-2.3511-6.5101-5.3189-13.532-8.899-7.5353-3.8873-12.662-7.7224-15.378-11.508-2.7174-3.7827-4.0755-8.1064-4.0755-12.964 0-6.3431 2.1641-11.613 6.4945-15.807 4.3293-4.1946 9.7985-6.2919 16.409-6.2919 7.6845 0 15.42 3.7593 23.209 11.279"/>
          <path d="m170.48 34.375h10.741v83.475h-10.741zm5.3334-34.375c2.4446 0 4.533 0.87053 6.264 2.6094 1.731 1.74 2.5971 3.8372 2.5971 6.2919 0 2.4045-0.86608 4.4762-2.5971 6.2151-1.731 1.74-3.8194 2.6083-6.264 2.6083-2.3945 0-4.4573-0.86831-6.1884-2.6083-1.731-1.7388-2.5971-3.8105-2.5971-6.2151 0-2.4546 0.86608-4.5519 2.5971-6.2919 1.731-1.7388 3.7938-2.6094 6.1884-2.6094"/>
          <path d="m245.52 43.504-6.9042 7.1335c-5.7475-5.5727-11.363-8.3625-16.848-8.3625-3.4866 0-6.4744 1.1522-8.9625 3.4554-2.4869 2.3021-3.7304 4.9839-3.7304 8.0563 0 2.7096 1.0242 5.2911 3.0758 7.7469 2.0483 2.5081 6.3564 5.447 12.916 8.8233 7.9984 4.1445 13.431 8.1331 16.302 11.97 2.8187 3.8885 4.228 8.2589 4.228 13.119 0 6.8541-2.4068 12.661-7.2236 17.415-4.8169 4.759-10.836 7.1379-18.057 7.1379-4.8169 0-9.4156-1.0486-13.793-3.1459-4.3794-2.0973-8.0051-4.9872-10.873-8.6697l6.7516-7.6734c5.4859 6.1895 11.304 9.2831 17.457 9.2831 4.3048 0 7.9695-1.3793 10.996-4.1423 3.0246-2.7619 4.5352-6.0113 4.5352-9.7462 0-3.068-0.99855-5.8032-2.9945-8.2099-1.9993-2.3511-6.5145-5.3189-13.532-8.899-7.5387-3.8873-12.665-7.7224-15.379-11.508-2.7207-3.7827-4.0755-8.1064-4.0755-12.964 0-6.3431 2.163-11.613 6.4923-15.807 4.3304-4.1946 9.7996-6.2919 16.41-6.2919 7.6845 0 15.42 3.7593 23.209 11.279"/>
          <path d="m273.9 3.3774h10.741v30.997h17.033v9.2831h-17.033v74.192h-10.741v-74.192h-14.654v-9.2831h14.654z"/>
          <path d="m356.46 42.738c-8.9302 0-16.605 3.2985-23.021 9.8965-6.4154 6.5969-9.6237 14.576-9.6237 23.94 0 6.0325 1.4594 11.662 4.3883 16.876 2.9233 5.2176 6.8763 9.2475 11.858 12.087 4.9772 2.8387 10.445 4.2558 16.399 4.2558s11.422-1.4171 16.399-4.2558c4.9816-2.8398 8.9313-6.8696 11.857-12.087 2.9266-5.2143 4.3927-10.844 4.3927-16.876 0-9.3632-3.2216-17.343-9.666-23.94-6.441-6.598-14.103-9.8965-22.982-9.8965m0.0378-10.512c12.861 0 23.519 4.6532 31.973 13.964 7.6878 8.4916 11.53 18.541 11.53 30.151 0 11.665-4.061 21.857-12.181 30.576-8.1231 8.722-18.562 13.082-31.321 13.082-12.811 0-23.275-4.3604-31.397-13.082-8.1231-8.7187-12.181-18.91-12.181-30.576 0-11.557 3.8417-21.583 11.528-30.075 8.4548-9.3599 19.138-14.041 32.049-14.041"/>
        </svg>
      </a>
    </ng-container>
  </vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

  <!-- Header -->
  <div class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <ng-container *ngIf="!isScreenSmall">
      <!-- Logo -->
      <a routerLink="/" class="no-underline cursor-default flex items-center mx-2 lg:mr-8">
        <div class="hidden lg:flex">
          <svg
            class="dark:hidden w-24 fill-current text-primary"
            version="1.1"
            viewBox="0 0 400 120"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg">
            <path d="m44.002 42.585c-5.9768 0-11.496 1.4706-16.555 4.4061-5.0596 2.9367-9.0816 7.0645-12.071 12.377-2.9879 5.3156-4.4829 10.933-4.4829 16.859 0 5.8789 1.5084 11.498 4.5208 16.862 3.0157 5.3646 7.0645 9.5413 12.146 12.529 5.084 2.989 10.539 4.4829 16.364 4.4829 5.8744 0 11.443-1.4806 16.706-4.444 5.261-2.9645 9.3109-6.9732 12.147-12.032 2.8354-5.0573 4.2525-10.756 4.2525-17.088 0-9.656-3.1793-17.73-9.5414-24.219-6.3609-6.49-14.191-9.7328-23.488-9.7328m42.928-8.2099v83.475h-10.59v-14.347c-4.4996 5.4726-9.5503 9.5892-15.152 12.351-5.6006 2.763-11.728 4.1456-18.375 4.1456-11.816 0-21.907-4.2848-30.268-12.853-8.3636-8.5651-12.545-18.987-12.545-31.266 0-12.017 4.2202-22.3 12.661-30.844 8.4393-8.5406 18.591-12.812 30.459-12.812 6.8529 0 13.055 1.4561 18.605 4.3727 5.5494 2.9188 10.422 7.2893 14.615 13.12v-15.343z"/>
            <path d="m153.07 43.504-6.9053 7.1335c-5.7464-5.5727-11.361-8.3625-16.846-8.3625-3.4888 0-6.4756 1.1522-8.9636 3.4554-2.4869 2.3021-3.7304 4.9839-3.7304 8.0563 0 2.7096 1.0264 5.2911 3.0758 7.7469 2.0505 2.5081 6.3553 5.447 12.918 8.8233 7.9962 4.1445 13.43 8.1331 16.3 11.97 2.8187 3.8885 4.2291 8.2589 4.2291 13.119 0 6.8541-2.409 12.661-7.2248 17.415-4.8158 4.759-10.834 7.1379-18.057 7.1379-4.8169 0-9.4133-1.0486-13.793-3.1459-4.3805-2.0973-8.0051-4.9872-10.873-8.6697l6.7516-7.6734c5.4848 6.1895 11.305 9.2831 17.456 9.2831 4.307 0 7.9717-1.3793 10.996-4.1423 3.0257-2.7619 4.5386-6.0113 4.5386-9.7462 0-3.068-0.99967-5.8032-2.999-8.2099-1.9993-2.3511-6.5101-5.3189-13.532-8.899-7.5353-3.8873-12.662-7.7224-15.378-11.508-2.7174-3.7827-4.0755-8.1064-4.0755-12.964 0-6.3431 2.1641-11.613 6.4945-15.807 4.3293-4.1946 9.7985-6.2919 16.409-6.2919 7.6845 0 15.42 3.7593 23.209 11.279"/>
            <path d="m170.48 34.375h10.741v83.475h-10.741zm5.3334-34.375c2.4446 0 4.533 0.87053 6.264 2.6094 1.731 1.74 2.5971 3.8372 2.5971 6.2919 0 2.4045-0.86608 4.4762-2.5971 6.2151-1.731 1.74-3.8194 2.6083-6.264 2.6083-2.3945 0-4.4573-0.86831-6.1884-2.6083-1.731-1.7388-2.5971-3.8105-2.5971-6.2151 0-2.4546 0.86608-4.5519 2.5971-6.2919 1.731-1.7388 3.7938-2.6094 6.1884-2.6094"/>
            <path d="m245.52 43.504-6.9042 7.1335c-5.7475-5.5727-11.363-8.3625-16.848-8.3625-3.4866 0-6.4744 1.1522-8.9625 3.4554-2.4869 2.3021-3.7304 4.9839-3.7304 8.0563 0 2.7096 1.0242 5.2911 3.0758 7.7469 2.0483 2.5081 6.3564 5.447 12.916 8.8233 7.9984 4.1445 13.431 8.1331 16.302 11.97 2.8187 3.8885 4.228 8.2589 4.228 13.119 0 6.8541-2.4068 12.661-7.2236 17.415-4.8169 4.759-10.836 7.1379-18.057 7.1379-4.8169 0-9.4156-1.0486-13.793-3.1459-4.3794-2.0973-8.0051-4.9872-10.873-8.6697l6.7516-7.6734c5.4859 6.1895 11.304 9.2831 17.457 9.2831 4.3048 0 7.9695-1.3793 10.996-4.1423 3.0246-2.7619 4.5352-6.0113 4.5352-9.7462 0-3.068-0.99855-5.8032-2.9945-8.2099-1.9993-2.3511-6.5145-5.3189-13.532-8.899-7.5387-3.8873-12.665-7.7224-15.379-11.508-2.7207-3.7827-4.0755-8.1064-4.0755-12.964 0-6.3431 2.163-11.613 6.4923-15.807 4.3304-4.1946 9.7996-6.2919 16.41-6.2919 7.6845 0 15.42 3.7593 23.209 11.279"/>
            <path d="m273.9 3.3774h10.741v30.997h17.033v9.2831h-17.033v74.192h-10.741v-74.192h-14.654v-9.2831h14.654z"/>
            <path d="m356.46 42.738c-8.9302 0-16.605 3.2985-23.021 9.8965-6.4154 6.5969-9.6237 14.576-9.6237 23.94 0 6.0325 1.4594 11.662 4.3883 16.876 2.9233 5.2176 6.8763 9.2475 11.858 12.087 4.9772 2.8387 10.445 4.2558 16.399 4.2558s11.422-1.4171 16.399-4.2558c4.9816-2.8398 8.9313-6.8696 11.857-12.087 2.9266-5.2143 4.3927-10.844 4.3927-16.876 0-9.3632-3.2216-17.343-9.666-23.94-6.441-6.598-14.103-9.8965-22.982-9.8965m0.0378-10.512c12.861 0 23.519 4.6532 31.973 13.964 7.6878 8.4916 11.53 18.541 11.53 30.151 0 11.665-4.061 21.857-12.181 30.576-8.1231 8.722-18.562 13.082-31.321 13.082-12.811 0-23.275-4.3604-31.397-13.082-8.1231-8.7187-12.181-18.91-12.181-30.576 0-11.557 3.8417-21.583 11.528-30.075 8.4548-9.3599 19.138-14.041 32.049-14.041"/>
          </svg>
          <svg
            class="hidden dark:flex w-24 fill-current text-primary"
            version="1.1"
            viewBox="0 0 400 120"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg">
            <path d="m44.002 42.585c-5.9768 0-11.496 1.4706-16.555 4.4061-5.0596 2.9367-9.0816 7.0645-12.071 12.377-2.9879 5.3156-4.4829 10.933-4.4829 16.859 0 5.8789 1.5084 11.498 4.5208 16.862 3.0157 5.3646 7.0645 9.5413 12.146 12.529 5.084 2.989 10.539 4.4829 16.364 4.4829 5.8744 0 11.443-1.4806 16.706-4.444 5.261-2.9645 9.3109-6.9732 12.147-12.032 2.8354-5.0573 4.2525-10.756 4.2525-17.088 0-9.656-3.1793-17.73-9.5414-24.219-6.3609-6.49-14.191-9.7328-23.488-9.7328m42.928-8.2099v83.475h-10.59v-14.347c-4.4996 5.4726-9.5503 9.5892-15.152 12.351-5.6006 2.763-11.728 4.1456-18.375 4.1456-11.816 0-21.907-4.2848-30.268-12.853-8.3636-8.5651-12.545-18.987-12.545-31.266 0-12.017 4.2202-22.3 12.661-30.844 8.4393-8.5406 18.591-12.812 30.459-12.812 6.8529 0 13.055 1.4561 18.605 4.3727 5.5494 2.9188 10.422 7.2893 14.615 13.12v-15.343z"/>
            <path d="m153.07 43.504-6.9053 7.1335c-5.7464-5.5727-11.361-8.3625-16.846-8.3625-3.4888 0-6.4756 1.1522-8.9636 3.4554-2.4869 2.3021-3.7304 4.9839-3.7304 8.0563 0 2.7096 1.0264 5.2911 3.0758 7.7469 2.0505 2.5081 6.3553 5.447 12.918 8.8233 7.9962 4.1445 13.43 8.1331 16.3 11.97 2.8187 3.8885 4.2291 8.2589 4.2291 13.119 0 6.8541-2.409 12.661-7.2248 17.415-4.8158 4.759-10.834 7.1379-18.057 7.1379-4.8169 0-9.4133-1.0486-13.793-3.1459-4.3805-2.0973-8.0051-4.9872-10.873-8.6697l6.7516-7.6734c5.4848 6.1895 11.305 9.2831 17.456 9.2831 4.307 0 7.9717-1.3793 10.996-4.1423 3.0257-2.7619 4.5386-6.0113 4.5386-9.7462 0-3.068-0.99967-5.8032-2.999-8.2099-1.9993-2.3511-6.5101-5.3189-13.532-8.899-7.5353-3.8873-12.662-7.7224-15.378-11.508-2.7174-3.7827-4.0755-8.1064-4.0755-12.964 0-6.3431 2.1641-11.613 6.4945-15.807 4.3293-4.1946 9.7985-6.2919 16.409-6.2919 7.6845 0 15.42 3.7593 23.209 11.279"/>
            <path d="m170.48 34.375h10.741v83.475h-10.741zm5.3334-34.375c2.4446 0 4.533 0.87053 6.264 2.6094 1.731 1.74 2.5971 3.8372 2.5971 6.2919 0 2.4045-0.86608 4.4762-2.5971 6.2151-1.731 1.74-3.8194 2.6083-6.264 2.6083-2.3945 0-4.4573-0.86831-6.1884-2.6083-1.731-1.7388-2.5971-3.8105-2.5971-6.2151 0-2.4546 0.86608-4.5519 2.5971-6.2919 1.731-1.7388 3.7938-2.6094 6.1884-2.6094"/>
            <path d="m245.52 43.504-6.9042 7.1335c-5.7475-5.5727-11.363-8.3625-16.848-8.3625-3.4866 0-6.4744 1.1522-8.9625 3.4554-2.4869 2.3021-3.7304 4.9839-3.7304 8.0563 0 2.7096 1.0242 5.2911 3.0758 7.7469 2.0483 2.5081 6.3564 5.447 12.916 8.8233 7.9984 4.1445 13.431 8.1331 16.302 11.97 2.8187 3.8885 4.228 8.2589 4.228 13.119 0 6.8541-2.4068 12.661-7.2236 17.415-4.8169 4.759-10.836 7.1379-18.057 7.1379-4.8169 0-9.4156-1.0486-13.793-3.1459-4.3794-2.0973-8.0051-4.9872-10.873-8.6697l6.7516-7.6734c5.4859 6.1895 11.304 9.2831 17.457 9.2831 4.3048 0 7.9695-1.3793 10.996-4.1423 3.0246-2.7619 4.5352-6.0113 4.5352-9.7462 0-3.068-0.99855-5.8032-2.9945-8.2099-1.9993-2.3511-6.5145-5.3189-13.532-8.899-7.5387-3.8873-12.665-7.7224-15.379-11.508-2.7207-3.7827-4.0755-8.1064-4.0755-12.964 0-6.3431 2.163-11.613 6.4923-15.807 4.3304-4.1946 9.7996-6.2919 16.41-6.2919 7.6845 0 15.42 3.7593 23.209 11.279"/>
            <path d="m273.9 3.3774h10.741v30.997h17.033v9.2831h-17.033v74.192h-10.741v-74.192h-14.654v-9.2831h14.654z"/>
            <path d="m356.46 42.738c-8.9302 0-16.605 3.2985-23.021 9.8965-6.4154 6.5969-9.6237 14.576-9.6237 23.94 0 6.0325 1.4594 11.662 4.3883 16.876 2.9233 5.2176 6.8763 9.2475 11.858 12.087 4.9772 2.8387 10.445 4.2558 16.399 4.2558s11.422-1.4171 16.399-4.2558c4.9816-2.8398 8.9313-6.8696 11.857-12.087 2.9266-5.2143 4.3927-10.844 4.3927-16.876 0-9.3632-3.2216-17.343-9.666-23.94-6.441-6.598-14.103-9.8965-22.982-9.8965m0.0378-10.512c12.861 0 23.519 4.6532 31.973 13.964 7.6878 8.4916 11.53 18.541 11.53 30.151 0 11.665-4.061 21.857-12.181 30.576-8.1231 8.722-18.562 13.082-31.321 13.082-12.811 0-23.275-4.3604-31.397-13.082-8.1231-8.7187-12.181-18.91-12.181-30.576 0-11.557 3.8417-21.583 11.528-30.075 8.4548-9.3599 19.138-14.041 32.049-14.041"/>
          </svg>
        </div>
          <svg
            class="flex lg:hidden w-8 fill-current text-primary"
            version="1.1"
            viewBox="0 0 1024 1024"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg">
            <path d="m888.31 1024h-752.6c-74.943 0-135.7-60.751-135.7-135.69v-752.61c0-74.943 60.761-135.69 135.7-135.69h752.6c74.943 0 135.69 60.751 135.69 135.69v752.61c0 74.943-60.751 135.69-135.69 135.69" />
            <path fill="#fff" d="m516.15 254.83c-46.074 0-88.619 11.328-127.6 33.963-39.008 22.636-70.008 54.448-93.049 95.388-23.032 40.98-34.548 84.279-34.548 129.96 0 45.311 11.625 88.619 34.845 129.97 23.24 41.347 54.448 73.536 93.624 96.568 39.186 23.052 81.226 34.568 126.13 34.568 45.281 0 88.193-11.417 128.77-34.261 40.544-22.854 71.762-53.754 93.634-92.742 21.853-38.978 32.774-82.901 32.774-131.71 0-74.428-24.509-136.67-73.546-186.68-49.027-50.018-109.38-75.012-181.03-75.012m330.88-63.278v643.42h-81.633v-110.59c-34.687 42.189-73.605 73.922-116.79 95.2-43.17 21.298-90.393 31.961-141.64 31.961-91.067 0-168.84-33.032-233.29-99.075-64.468-66.014-96.697-146.35-96.697-240.99 0-92.623 32.526-171.88 97.579-237.74 65.062-65.825 143.31-98.748 234.78-98.748 52.823 0 100.63 11.229 143.4 33.696 42.774 22.497 80.334 56.192 112.65 101.14v-118.26z" />
          </svg>
        </a>
      <!-- Horizontal navigation -->
      <horizontal-navigation
        class="mr-2"
        [name]="'mainNavigation'"
        [navigation]="navigation.horizontal"></horizontal-navigation>
    </ng-container>
    <!-- Navigation toggle button -->
    <ng-container *ngIf="isScreenSmall">
      <button
        mat-icon-button
        (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
    </ng-container>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
      <languages></languages>
      <fullscreen class="hidden md:block"></fullscreen>
      <search [appearance]="'bar'"></search>
      <!-- <notifications></notifications> -->
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
          Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <footer></footer>

</div>
