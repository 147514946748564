import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Title strategy, uses the title declared in the route configuration as the resource key to get
 * the translation given by the Transloco service.
 */
@Injectable({
	providedIn: 'root',
})
export class TranslocoTitleStrategy extends TitleStrategy
{

	// -----------------------------------------------------------------------------------------------------
	// @ Constructor
	// -----------------------------------------------------------------------------------------------------

	/**
	 * @constructor
	 * Constructor with service injection.
	 * @param _window > the window object.
	 */
	constructor(
		private _title: Title,
		private _translocoService: TranslocoService
	)
	{
		super();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * @method updateTitle
	 * Sets the title of the route specified by the router snapshot object with the translation
     * corresponding to the title of the routerSnapshot object passed by parameter.
	 * @param routerSnapshot > the object with the state of the router in a specific moment
     * (including the route url and the data of the title -the resource identifier).
	 */
	public updateTitle(routerSnapshot: RouterStateSnapshot): void
	{
        const title = this.buildTitle(routerSnapshot);
        const titleTranslation = title !== undefined
            ? this._translocoService.translate(title)
            : this._translocoService.translate('titleDefault');
        this._title.setTitle(titleTranslation);
	}

}
