import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SplashScreenService
{

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * Constructor
   */
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _router: Router
  )
  {
    // Hide it on the first NavigationEnd event
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() =>
        {
            this.hide();
        });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method show
   * Show the splash screen
   */
  show(): void
  {
    this._document.body.classList.remove('splash-screen-hidden');
  }

  /**
   * @method hide
   * Hide the splash screen
   */
  hide(): void
  {
    this._document.body.classList.add('splash-screen-hidden');
  }

}
