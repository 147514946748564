import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * @author jmgonzalezr
 * @version 1.0
 * This service fires an observable value (to the observers listening) when any of its methods is
 * called; false for hide and true for show.
 */
@Injectable({
  providedIn: 'root'
})
export class HttpRequestService
{
  // -----------------------------------------------------------------------------------------------------
  // @ Properties
  // -----------------------------------------------------------------------------------------------------

  /** this variable contains the number of pending http requests in a moment */
  private _pendingRequests: number;
  /** sends (and remembers the last 1 value) a true value when an HTTP request is started, and a false value when it is finished */
  private _subject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * @constructor
   * Creates an instance of the HttpRequestService service, that controls whether there is a
   * request pending or not. Initializes the counter of pending requests.
   */
  constructor()
  {
    this._pendingRequests = 0;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method loading$
   * This getter function returns the Observable of HTTP requests, passing boolean values wheren there
   * is a request pending (true) or not (false), for anyone who wants can subscribe to it.
   * @returns the Observable of the loading status
   */
  get loading$(): Observable<boolean>
  {
    return this._subject;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method increment
   * this function increments the number of pending requests and, if the total number is 1 raises the flag
   * of active pending requests (because there is at least one pending request).
   */
  public increment(): void
  {
    this._pendingRequests++;
    if (1 === this._pendingRequests)
    {
      this._subject.next(true);
    }
  }

  /**
   * @method decrement
   * this function decrements the number of pending requests and, if the total number is 0 lowers the flag
   * of active pending requests (because there are no pending requests).
   */
  public decrement(): void
  {
    this._pendingRequests--;
    if (0 === this._pendingRequests)
    {
      this._subject.next(false);
    }
  }

}
