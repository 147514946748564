import { Component, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Settings } from 'luxon';
import { Subject, takeUntil } from 'rxjs';
import { AppConfig } from 'app/app.settings';
import { ConfigService } from 'app/core/config/config.service';
import { MessageService } from 'app/core/services/message.service';
import { LocalStorageService } from 'app/core/storage/local-storage.service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [MatSnackBarModule, RouterOutlet]
})
export class AppComponent implements OnDestroy
{

  // -----------------------------------------------------------------------------------------------------
  // @ Properties
  // -----------------------------------------------------------------------------------------------------

  /** Contains the (current) configuration */
  private _config: AppConfig;
  /** This variable is used as a flag to close all the open subscriptions in the component */
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * @constructor
   * Sets the default locale based on the user's browser configuration.
   */
  constructor(
    private _configService: ConfigService,
    private _localStorageService: LocalStorageService,
    private _messageService: MessageService,
    private _snackBar: MatSnackBar,
    private _translocoService: TranslocoService
  )
  {
    // Subscribes to the configuration service
    this._configService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: AppConfig) => {
        // Configures default scheme (local storage)
        const scheme = this._localStorageService.scheme;
        if (scheme)
        {
          config.scheme = scheme;
        }
        // Configures default language (local storage)
        const language = this._localStorageService.language;
        if (language)
        {
          config.language = language;
        }
        // Configures show errors service
        this._messageService.messenger$.subscribe((message) =>
        {
          if (message)
          {
            this._snackBar.open(
              this._translocoService.translate(message.Text, message.Params),
              undefined,
              {
                duration: config.snackBarDuration,
                horizontalPosition: config.snackBarPosition.horizontal,
                verticalPosition: config.snackBarPosition.vertical,
                panelClass: [message.Type]
              }
            );
          }
        });
        // Configures Luxon locale
        Settings.defaultLocale = config.language;
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method ngOnDestroy
   * Frees the subscriptions made in the component.
   */
  public ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public closeSnackBar(): void
  {
    this._snackBar.dismiss();
  }

}
