import { EnvironmentProviders, Provider } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { TranslocoTitleStrategy } from 'app/core/titles/transloco-title-strategy';

export const provideTitleStrategy = (): Array<Provider | EnvironmentProviders> =>
{
	return [
		{
			provide: TitleStrategy,
			useClass: TranslocoTitleStrategy
		}
	];
};
