/**
 * @function randomId
 * @description Generates a random id.
 * @param length > the length of the string to generate.
 * @returns the random generated string.
 */
export function randomId(length: number = 10): string
{
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let name = '';
  for (let i = 0; i < length; i++)
  {
    name += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return name;
}

/**
 * @method toUpper
 * Converts the values of the properties of an object into its upppercase representation.
 * @param original > the object whose property values to process.
 */
export function toUpper(original: object): object
{
  const copy = {};
  Object.entries(original)
    .forEach(([k, v]) => copy[k] = typeof original[k] === 'string' ? v.toUpperCase() : v);
  return copy;
}
