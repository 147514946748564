
import { HttpRequest } from '@angular/common/http';

// -----------------------------------------------------------------------------------------------------
// @ AUTH UTILITIES
//
// Methods are derivations of the Auth0 Angular-JWT helper service methods
// https://github.com/auth0/angular2-jwt
// -----------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------------
// @ Public methods
// -----------------------------------------------------------------------------------------------------

/**
 * @function isTokenExpired
 * Is token expired?
 * @param token > the token to analyze.
 * @param offsetSeconds > Minimum number of seconds of life that the token must have to
 * consider it active (not expired).
 */
export function isTokenExpired(token: string, offsetSeconds?: number): boolean
{
  // Return if there is no token
  if (!token || token === '')
  {
    return true;
  }
  // Get the expiration date
  const date = _getTokenExpirationDate(token);
  if (date === null)
  {
    return true;
  }
  // Check if the token is expired
  return !(date.valueOf() > new Date().valueOf() + (offsetSeconds || 0) * 1000);
}

/**
 * @function setToken
 * @description clones the {HttpRequest<unknown>} object but setting the Authorization header with
 * the Bearer token passed by parameter.
 * @param request > the HttpRequest to set the header.
 * @param token > the token to set in the Authorization header of the HttpRequest.
 * @returns {HttpRequest<unknown>} cloned from the original HttpRequest, but with the Authorization
 * header set with the given token.
 */
export function setToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown>
{
  return request.clone({
    headers: request.headers.set('Authorization', `Bearer ${token}`)
  });
}

// -----------------------------------------------------------------------------------------------------
// @ Private methods
// -----------------------------------------------------------------------------------------------------

/**
 * Base64 decoder
 * Credits: https://github.com/atk
 *
 * @param str
 * @private
 */
function _b64decode(str: string): string
{
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let output = '';

  str = String(str).replace(/=+$/, '');

  if (str.length % 4 === 1)
  {
    throw new Error(
    '\'atob\' failed: The string to be decoded is not correctly encoded.'
    );
  }

  /* eslint-disable */
  for (
    // initialize result and counters
    let bc = 0, bs: any, buffer: any, idx = 0;
    // get next character
    (buffer = str.charAt(idx++));
    // character found in table? initialize bit storage and add its ascii value;
    ~buffer &&
    (
      (bs = bc % 4 ? bs * 64 + buffer : buffer),
        // and if not first of each 4 characters,
        // convert the first 8 bits to one ascii character
      bc++ % 4
    )
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  )
  {
    // try to find character in table (0-63, not found => -1)
    buffer = chars.indexOf(buffer);
  }
  /* eslint-enable */

  return output;
}

/**
 * Base64 unicode decoder
 *
 * @param str
 * @private
 */
function _b64DecodeUnicode(str: string): string
{
  return decodeURIComponent(
    Array.prototype.map
    .call(_b64decode(str), (c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
    .join('')
  );
}

/**
 * URL Base 64 decoder
 *
 * @param str
 * @private
 */
function _urlBase64Decode(str: string): string
{
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4)
  {
    case 0:
    {
      break;
    }
    case 2:
    {
      output += '==';
      break;
    }
    case 3:
    {
      output += '=';
      break;
    }
      default:
    {
      throw Error('Illegal base64url string!');
    }
  }
  return _b64DecodeUnicode(output);
}

/**
 * Decode token
 *
 * @param token
 * @private
 */
function _decodeToken(token: string): { exp: number; [key: string]: unknown; }
{
  // Return if there is no token
  if (!token)
  {
    return null;
  }

  // Split the token
  const parts = token.split('.');

  if (parts.length !== 3)
  {
    throw new Error('The inspected token doesn\'t appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more.');
  }

  // Decode the token using the Base64 decoder
  const decoded = _urlBase64Decode(parts[1]);

  if (!decoded)
  {
    throw new Error('Cannot decode the token.');
  }

  return JSON.parse(decoded);
}

/**
 * Get token expiration date
 *
 * @param token
 * @private
 */
function _getTokenExpirationDate(token: string): Date | null
{
  // Get the decoded token
  const decodedToken = _decodeToken(token);

  // Return if the decodedToken doesn't have an 'exp' field
  if (!Object.prototype.hasOwnProperty.call(decodedToken, 'exp'))
  {
    return null;
  }

  // Convert the expiration date
  const date = new Date(0);
  date.setUTCSeconds(decodedToken.exp);

  return date;
}
