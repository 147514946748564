import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OfficialBodyLoginCommunication, User, UserContract, WorkCenterConfiguration, WorkCenterConfigurationExternalWeb } from 'app/core/core.types';
import { STORAGE_KEYS, WINDOW } from 'app/core/storage';
import { StorageService } from 'app/core/storage/storage.service';
import { DateTime } from 'luxon';

/**
 * Session storage service, used for persist application data in observable key value pairs.
 */
 @Injectable({
  providedIn: 'root'
})
export class SessionStorageService extends StorageService
{

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * @constructor
   * Constructor with service injection.
   * @param _window > the window object.
   */
  constructor(@Inject(WINDOW) private _window: Window)
  {
    super(_window.sessionStorage);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Gets the default official body login communication object to be used in the communications
   * area of the application.
   */
  public get communication(): OfficialBodyLoginCommunication
  {
    return this.get<OfficialBodyLoginCommunication>(STORAGE_KEYS.communication);
  }

  /**
   * Gets the default date used for rendering the planning views in the application.
   */
  public get date(): DateTime
  {
    return this.get<DateTime>(STORAGE_KEYS.date);
  }

  /**
   * Gets the default user used for rendering in the user area in the application.
   */
  public get user(): User
  {
    return this.get<User>(STORAGE_KEYS.user);
  }

  /**
   * Gets an observable for the user used in the User area in the application.
   */
  public get user$(): Observable<User>
  {
    return this.watch<User>(STORAGE_KEYS.user);
  }

  /**
   * Gets the default user contract used for rendering in the user contract Area in the
   * application.
   */
  public get userContract(): UserContract
  {
    return this.get<UserContract>(STORAGE_KEYS.userContract);
  }

  /**
   * Gets the default configuration values for the web.
   */
  public get webConfiguration(): WorkCenterConfigurationExternalWeb
  {
    return this.get<WorkCenterConfigurationExternalWeb>(STORAGE_KEYS.webConfiguration);
  }

  /**
   * Gets an observable for the current web configuration used in the User and other areas in the application.
   */
  public get webConfiguration$(): Observable<WorkCenterConfigurationExternalWeb>
  {
    return this.watch<WorkCenterConfigurationExternalWeb>(STORAGE_KEYS.webConfiguration);
  }

  /**
   * Gets the work center configuration values.
   */
  public get workCenterConfiguration(): WorkCenterConfiguration
  {
    return this.get<WorkCenterConfiguration>(STORAGE_KEYS.workCenterConfiguration);
  }

  /**
   * Gets an observable for the work center configuration used in several areas in the application.
   */
  public get workCenterConfiguration$(): Observable<WorkCenterConfiguration>
  {
    return this.watch<WorkCenterConfiguration>(STORAGE_KEYS.workCenterConfiguration);
  }

  /**
   * Sets the default official body login communication object to use in the communications area
   * in the application.
   */
  public set communication(value: OfficialBodyLoginCommunication)
  {
    this.set<OfficialBodyLoginCommunication>(STORAGE_KEYS.communication, value);
  }

  /**
   * Sets the default date to use in the planning area in the application.
   */
  public set date(value: DateTime)
  {
    this.set<string>(STORAGE_KEYS.date, value.toISO());
  }

  /**
   * Sets the default user to use in the user area in the application.
   */
  public set user(value: User)
  {
    this.set<User>(STORAGE_KEYS.user, value);
  }

  /**
   * Sets the default user contract to use in the user Area in the application.
   */
  public set userContract(value: UserContract)
  {
    this.set<UserContract>(STORAGE_KEYS.userContract, value);
  }

  /**
   * Sets some configuration values for the current web configuration.
   */
  public set webConfiguration(value: WorkCenterConfigurationExternalWeb)
  {
    this.set<WorkCenterConfigurationExternalWeb>(STORAGE_KEYS.webConfiguration, value);
  }

  /**
   * Sets the work center configuration values.
   */
  public set workCenterConfiguration(value: WorkCenterConfiguration)
  {
    this.set<WorkCenterConfiguration>(STORAGE_KEYS.workCenterConfiguration, value);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method hasDate
   * This method checks if the session storage has a DateTime object stored or not.
   * @returns true if there is a DateTime object stored in the session storage, false
   * otherwise.
   */
  public hasDate(): boolean
  {
    return this.has(STORAGE_KEYS.date);
  }

  /**
   * @method hasUser
   * This method checks if the session storage has a User object stored or not.
   * @returns true if there is a User object stored in the session storage, false
   * otherwise.
   */
  public hasUser(): boolean
  {
    return this.has(STORAGE_KEYS.user);
  }

  /**
   * @method hasWebConfiguration
   * This method checks if the session storage has an WorkCenterExternalWebConfiguration object
   * stored or not.
   * @returns true if there is a WorkCenterExternalWebConfiguration object stored in
   * the session storage, false otherwise.
   */
  public hasWebConfiguration(): boolean
  {
    return this.has(STORAGE_KEYS.webConfiguration);
  }

  /**
   * @method hasWorkCenterConfiguration
   * This method checks if the session storage has an WorkCenterConfiguration object stored or not.
   * @returns true if there is a WorkCenterConfiguration object stored in the session
   * storage, false otherwise.
   */
  public hasWorkCenterConfiguration(): boolean
  {
    return this.has(STORAGE_KEYS.webConfiguration);
  }

  /**
   * @method removeCommunication
   * Removes the official body login communication from the session storage.
   */
  public removeCommunication(): void
  {
    this.remove(STORAGE_KEYS.communication);
  }

  /**
   * @method removeDate
   * Removes the date from the session storage.
   */
  public removeDate(): void
  {
    this.remove(STORAGE_KEYS.date);
  }

  /**
   * @method removeUser
   * Removes the user from the session storage.
   */
  public removeUser(): void
  {
    this.remove(STORAGE_KEYS.user);
  }

  /**
   * @method removeUserContract
   * Removes the user contract from the session storage.
   */
  public removeUserContract(): void
  {
    this.remove(STORAGE_KEYS.userContract);
  }

  /**
   * @method removeWebConfiguration
   * Removes the configuration object from the session storage.
   */
  public removeWebConfiguration(): void
  {
    this.remove(STORAGE_KEYS.webConfiguration);
  }

  /**
   * @method removeWorkCenterConfiguration
   * Removes the work center configuration from the session storage.
   */
  public removeWorkCenterConfiguration(): void
  {
    this.remove(STORAGE_KEYS.workCenterConfiguration);
  }

}
