import { Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AppConfig } from 'app/app.settings';
import { Navigation, NavigationItem, NavigationService } from 'app/components/navigation';
import { ConfigService } from 'app/core/config/config.service';
import { MenuService } from 'app/core/services/menu.service';
import { UserService } from 'app/shared/services/user.service';
import { cloneDeep } from 'lodash-es';
import { map, Observable, of, Subject, takeUntil } from 'rxjs';

@Injectable()
export class SearchService implements OnDestroy
{

  // -----------------------------------------------------------------------------------------------------
  // @ Properties
  // -----------------------------------------------------------------------------------------------------

  /** Contains the (current) configuration */
  private _config: AppConfig;
  /** Contains the navigation configuration */
  private _defaultNavigation: NavigationItem[];
  /** Used to free the subscriptions made for the current service */
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * @constructor
   * Subscribes to the config service in order to know the default API uri.
   */
  constructor(
    private _configService: ConfigService,
    private _menuService: MenuService,
    private _navigationService: NavigationService,
    private _translocoService: TranslocoService,
    private _userService: UserService
  )
  {
    // Subscribe to the application configuration
    this._configService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: AppConfig) => this._config = config);
    // Subscribe to navigation data and gets a flattened array with the translations of all the items
    this._menuService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) =>
      {
        this._defaultNavigation = this._navigationService.getFlatNavigation(navigation.default);
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method ngOnDestroy
   * Frees the subscriptions made in the service.
   */
  public ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method getSearch
   * Performs a search over the pages of the site and the users of the specified workcenter by the
   * specified search term, filtered, paged, and filled only with its simple properties.
   */
  public getSearch(term: string): Observable<object[]>
  {
    // Get the search query
    const query = cloneDeep(term.toLowerCase());

    // If the search query is an empty string, returns an empty array
    if (query === '')
    {
      return of([{ results: [] }]);
    }

    // Creates a variable for working with the navigation items
    const defaultNavigation = cloneDeep(this._defaultNavigation);
    // Translates the titles of all the items in the default navigation
    defaultNavigation.forEach(item => item.title = this._translocoService.translate(item.title));

    // Filter the navigation
    const pagesResults = defaultNavigation.filter(item => item.title.toLowerCase().includes(query));

    // Prepare the results array
    const results = [];

    // If there are page results...
    if (pagesResults.length > 0)
    {
      // Add to the results
      results.push(
      {
        id     : 'pages',
        label  : this._translocoService.translate('pages'),
        results: pagesResults
      });
    }

    // Returns the result of the static 'pages' concatenated with the 'users' search
    return this._userService.getSearch(query)
      .pipe(
        map((usersResults) =>
        {
          // If there are user results...
          if (usersResults.length > 0)
          {
            // Add to the results
            results.push(
            {
              id     : 'users',
              label  : this._translocoService.translate('users'),
              results: usersResults.map(userResult => (
                {
                  id      : userResult.Id,
                  fullName: userResult.FullName,
                  link    : `/users/user/${userResult.Id}/profile/detail`,
                  photoUrl: userResult.Photo ? `${this._config.authApi}/${userResult.Photo}` : null
                }
              ))
            });
          }
          return results;
        })
      );
  }

}
