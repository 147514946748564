import { Injectable } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { CopaymentTypes, ExternalWebInfo } from 'app/app.settings';
import { WorkCenterConfiguration, WorkCenterConfigurationExternalWeb, WorkCenterConfigurationExternalWebInfo } from 'app/core/core.types';

// TODO: review this service. I think is not well thought. Why static methods...¿?
@Injectable({
  providedIn: 'root'
})
export class UtilService
{

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the equivalent "IsActiveMatchOptions" options for "exact = true".
   */
  static get exactMatchOptions(): IsActiveMatchOptions
  {
    return {
      paths       : 'exact',
      fragment    : 'ignored',
      matrixParams: 'ignored',
      queryParams : 'exact'
    };
  }

  /**
   * Get the equivalent "IsActiveMatchOptions" options for "exact = false".
   */
  static get subsetMatchOptions(): IsActiveMatchOptions
  {
    return {
      paths       : 'subset',
      fragment    : 'ignored',
      matrixParams: 'ignored',
      queryParams : 'subset'
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method isUsingPercentageCopayment
   * Checks if the work center web configuration object passed by parameter has the
   * percentual copayment active or not (that is, if the copayment is in a percentage format for
   * the associated work center).
   * @param workCenterConfiguration > the work center configuration object whose CopaymentType
   * property is going to be tested to know if the percentage flag is active.
   * @returns {boolean} true if the work center associated to the work center configuration object
   * has the percentual copayment flag active, false otherwise.
   */
  public static isUsingPercentageCopayment(workCenterConfiguration: WorkCenterConfiguration): boolean
  {
    return !!(workCenterConfiguration.CopaymentType & CopaymentTypes.percentage); //eslint-disable-line no-bitwise
  }

  /**
   * @method viewCarerTravelTime
   * Checks if the external web configuration object passed by parameter has the
   * ViewCarerTravelTime active or not.
   * @param webConfiguration > the external web configuration object whose Infos property is going
   * to be check for the presence of the ViewCarerTravelTime property.
   * @returns {boolean} true if the ViewCarerTravelTime property is active for the
   * associated workcenter, false otherwise.
   */
  public static viewCarerTravelTime(webConfiguration: WorkCenterConfigurationExternalWeb): boolean
  {
    return this._hasInfo(webConfiguration.Infos, ExternalWebInfo.viewCarerTravelTime);
  }

  /**
   * @method viewInfoPage
   * Checks if the external web configuration object passed by parameter has the
   * ViewInfoPage active or not.
   * @param webConfiguration > the external web configuration object whose Infos property is going
   * to be check for the presence of the ViewInfoPage property.
   * @returns {boolean} true if the ViewInfoPage property is active for the
   * associated workcenter, false otherwise.
   */
  public static viewInfoPage(webConfiguration: WorkCenterConfigurationExternalWeb): boolean
  {
    return this._hasInfo(webConfiguration.Infos, ExternalWebInfo.viewInfoPage);
  }

  /**
   * @method viewTasksPage
   * Checks if the external web configuration object passed by parameter has the
   * ViewTasksPage active or not.
   * @param webConfiguration > the external web configuration object whose Infos property is going
   * to be check for the presence of the ViewTasksPage property.
   * @returns {boolean} true if the ViewTasksPage property is active for the
   * associated workcenter, false otherwise.
   */
  public static viewTasksPage(webConfiguration: WorkCenterConfigurationExternalWeb): boolean
  {
    return this._hasInfo(webConfiguration.Infos, ExternalWebInfo.viewTasksPage);
  }

  /**
   * @method viewUserContractWeeklyHours
   * Checks if the external web configuration object passed by parameter has the
   * ViewUserContractWeeklyHours active or not.
   * @param webConfiguration > the external web configuration object whose Infos property is going
   * to be check for the presence of the ViewUserContractWeeklyHours property.
   * @returns {boolean} true if the ViewUserContractWeeklyHours property is active for the
   * associated workcenter, false otherwise.
   */
  public static viewUserContractWeeklyHours(webConfiguration: WorkCenterConfigurationExternalWeb): boolean
  {
    return this._hasInfo(webConfiguration.Infos, ExternalWebInfo.viewUserContractWeeklyHours);
  }

  /**
   * @method viewUserFinancialData
   * Checks if the external web configuration object passed by parameter has the
   * ViewUserFinancialData active or not.
   * @param webConfiguration > the external web configuration object whose Infos property is going
   * to be check for the presence of the ViewUserFinancialData property.
   * @returns {boolean} true if the ViewUserFinancialData property is active for the
   * associated workcenter, false otherwise.
   */
  public static viewUserFinancialData(webConfiguration: WorkCenterConfigurationExternalWeb): boolean
  {
    return this._hasInfo(webConfiguration.Infos, ExternalWebInfo.viewUserFinancialData);
  }

  /**
   * @method viewUserProfilePage
   * Checks if the external web configuration object passed by parameter has the
   * ViewUserProfilePage active or not.
   * @param webConfiguration > the external web configuration object whose Infos property is going
   * to be check for the presence of the ViewUserProfilePage property.
   * @returns {boolean} true if the ViewUserProfilePage property is active for the
   * associated workcenter, false otherwise.
   */
  public static viewUserProfilePage(webConfiguration: WorkCenterConfigurationExternalWeb): boolean
  {
    return this._hasInfo(webConfiguration.Infos, ExternalWebInfo.viewUserProfilePage);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method _hasInfo
   * This method checks if the external configuration object passed by parameter contains the
   * configuration property passed by parameter (meaning that that property is active for the work
   * center associated to the external web object).
   * @param infos > the external web configuration Infos (array= object where to check if it
   * contains the specified info property.
   * @param info > the property to check (is present?).
   * @returns {boolean} true if the property is present, false otherwise.
   */
  private static _hasInfo(infos: WorkCenterConfigurationExternalWebInfo[], info: ExternalWebInfo): boolean
  {
    for (const i of infos)
    {
      if (i.Id === info)
      {
        return true;
      }
    }
    return false;
  }

}
