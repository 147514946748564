import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AppConfig } from 'app/app.settings';
import { Navigation, NavigationItem } from 'app/components/navigation';
import { ConfigService } from 'app/core/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService implements OnDestroy
{

  // -----------------------------------------------------------------------------------------------------
  // @ Properties
  // -----------------------------------------------------------------------------------------------------

  /** Contains the (current) configuration */
  private _config: AppConfig;
  /** contains the list of nodes that the currently logged user can see */
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
  /** Used to free the subscriptions made for the current service */
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  /** contains the list of nodes that the currently logged user can see in the user submenu */
  private _userNavigation: ReplaySubject<NavigationItem[]> = new ReplaySubject<NavigationItem[]>(1);

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * @constructor
   * Subscribes to the config service in order to know the default API uri.
   */
  constructor(
    private _configService: ConfigService,
    private _httpClient: HttpClient
  )
  {
    this._configService.config$
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((config: AppConfig) => this._config = config);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation>
  {
    return this._navigation.asObservable();
  }
  /**
   * Getter for the user submenu navigation
   */
  get userNavigation$(): Observable<NavigationItem[]>
  {
    return this._userNavigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method ngOnDestroy
   * Frees the subscriptions made in the service.
   */
  public ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method get
   * Get all navigation data
   * @returns an object with the nodes that the current official body login can see
   * in the main menu.
   */
  public get(): Observable<Navigation>
  {
    return this._httpClient.get<NavigationItem[]>(`${this._config.webApi}/officialbody-login/navigation`).pipe(
      map(items => ({
          compact: items,
          default: items,
          futuristic: items,
          horizontal: items
        } as Navigation)),
      tap(navigation =>
        this._navigation.next(navigation)
      )
    );
  }

  /**
   * @method getUser
   * Get the user submenu navigation data.
   * @returns an object with the nodes that the current official body login can see
   * in the user submenu.
   */
  public getUser(): Observable<NavigationItem[]>
  {
    return this._httpClient.get<NavigationItem[]>(`${this._config.webApi}/officialbody-login/user-navigation`).pipe(
      tap(navigationItems =>
        this._userNavigation.next(navigationItems)
      )
    );
  }

}
