import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { animations } from 'app/core/animations/public-api';
import { SearchService } from 'app/shared/services/search.service';
import { UserService } from 'app/shared/services/user.service';
import { has } from 'lodash-es';
import { debounceTime, filter, map, Subject, takeUntil } from 'rxjs';

@Component({
  selector     : 'search',
  templateUrl  : './search.component.html',
  encapsulation: ViewEncapsulation.None,
  exportAs     : 'search',
  animations   : animations,
  standalone   : true,
  // eslint-disable-next-line max-len
  imports      : [FormsModule, MatAutocompleteModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatOptionModule, NgClass, NgFor, NgIf, NgTemplateOutlet, ReactiveFormsModule, RouterLink, TranslocoPipe],
  providers    : [
    {
      provide   : MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (): () => ScrollStrategy =>
      {
        const overlay = inject(Overlay);
        return () => overlay.scrollStrategies.block();
      }
    },
    SearchService
  ]
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy
{
  @Input() appearance: 'basic' | 'bar' = 'basic';
  @Input() debounce = 300;
  @Input() minLength = 3;
  @Output() search = new EventEmitter<object[]>();

  opened = false;
  resultSets: object[];
  searchControl = new UntypedFormControl();
  private _matAutocomplete: MatAutocomplete;
  private _unsubscribeAll = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private _elementRef: ElementRef,
    private _renderer2: Renderer2,
    private _router: Router,
    private _searchService: SearchService,
    private _userService: UserService
  )
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): { [className: string]: boolean; }
  {
    return {
      'search-appearance-bar'  : this.appearance === 'bar',
      'search-appearance-basic': this.appearance === 'basic',
      'search-opened'          : this.opened
    };
  }

  /**
   * Setter for bar search input
   *
   * @param value
   */
  @ViewChild('barSearchInput')
  set barSearchInput(value: ElementRef)
  {
    // If the value exists, it means that the search input
    // is now in the DOM and we can focus on the input..
    if (value)
    {
      // Give Angular time to complete the change detection cycle
      setTimeout(() => {
        // Focus to the input element
        value.nativeElement.focus();
      });
    }
  }

  /**
   * Setter for mat-autocomplete element reference
   *
   * @param value
   */
  @ViewChild('matAutocomplete')
  set matAutocomplete(value: MatAutocomplete)
  {
    this._matAutocomplete = value;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On changes
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void
  {
    // Appearance
    if ('appearance' in changes)
    {
      // To prevent any issues, close the
      // search after changing the appearance
      this.close();
    }
  }

  /**
   * On init
   */
  public ngOnInit(): void
  {
    // Subscribe to the search field value changes
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.debounce),
        takeUntil(this._unsubscribeAll),
        map((value) => {
            // Set the resultSets to null if there is no value or
            // the length of the value is smaller than the minLength
            // so the autocomplete panel can be closed
            if (!value || value.length < this.minLength)
            {
                this.resultSets = null;
            }

            // Continue
            return value;
        }),
        // Filter out undefined/null/false statements and also
        // filter out the values that are smaller than minLength
        filter(value => value && value.length >= this.minLength)
      )
      .subscribe((value) => {
        this._searchService.getSearch(value)
          .subscribe((resultSets) => {
            // Store the result sets
            this.resultSets = resultSets;

            // Execute the event
            this.search.next(resultSets);
          });
      });
  }

  /**
   * On destroy
   */
  public ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Close the search
   * * Used in 'bar'
   */
  public close(): void
  {
    // Return if it's already closed
    if (!this.opened)
    {
      return;
    }

    // Clear the search input
    this.searchControl.setValue('');

    // Close the search
    this.opened = false;
  }

  /**
   * Opens one of the suggestions
   * * Used in 'bar'
   */
  public navigate(result: { id: number; link: string }): void
  {
    // Check if it is need a user loading
    if (has(result, 'fullName'))
    {
      this._userService.getOne(result.id).subscribe(() =>
      {
        // Redirects to the user details page
        this._router.navigateByUrl(result.link);
      });
    }
    // Redirects to the selected page
    else
    {
      this._router.navigateByUrl(result.link);
    }
  }

  /**
   * On keydown of the search input
   *
   * @param event
   */
  public onKeydown(event: KeyboardEvent): void
  {
    // Escape
    if (event.code === 'Escape')
    {
      // Listen for escape to close the search if the appearance is 'bar'
      if (this.appearance === 'bar' && !this._matAutocomplete.isOpen)
      {
        // Close the search
        this.close();
      }
    }
  }

  /**
   * Open the search
   * Used in 'bar'
   */
  public  open(): void
  {
    // Return if it's already opened
    if (this.opened)
    {
      return;
    }

    // Open the search
    this.opened = true;
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  public trackByFn(index: number, item: { id?: string | number }): string | number
  {
    return item.id || index;
  }

}
