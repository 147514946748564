import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanActivateChildFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'app/core/auth';

/**
 * @function authGuard
 * This function is used to ensure that some URL are accesed only when having set the Authorization
 * header, or redirects the flow to the sign-in page if not.
 * @param route > the route requested
 * @param state > an object that contains information of the status of the URL, like the query params.
 * @returns {boolean|UrlTree} true if the URL is allowed to access, an UrlTree with the URL to go
 * if not.
 */
export const authGuard: CanActivateFn | CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
{
	const authService: AuthService = inject(AuthService);
	const router: Router = inject(Router);

    // parses the current url
    const urlTree: UrlTree = router.parseUrl(state.url);
	// gets the URL without the query string
	const urlWithoutQueryString: string = urlTree.root.children['primary'].toString();
	// gets the redirectUrl parameter
	const redirectUrl: string = urlTree.queryParams['redirectUrl'];

	// checks the authenticated status of the current user
	if (authService.isAuthenticated())
	{
		// checks if the user has changed the password
		if (authService.officialBodyLogin.LastExternalLogin)
        {
            // allows access
            return true;
        }
        else
        {
            if (urlWithoutQueryString === 'security')
            {
                // allows access
                return true;
            }
            else
            {
                // returns the UrlTree for changing the password (and then going to the requested page)
			    return router.parseUrl(redirectUrl && redirectUrl !== '/sign-out'
                    ? `security?redirectURL=${redirectUrl}`
                    : 'security');
            }
        }
	}
	else
	{
		// redirects to the sign-in page with a redirectUrl param
		return router.parseUrl(redirectUrl && redirectUrl !== '/sign-out'
            ? `sign-in?redirectURL=${redirectUrl}`
            : 'sign-in');
	}
}
