import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FullscreenComponent } from 'app/components/fullscreen';
import { Navigation, NavigationService, VerticalNavigationComponent } from 'app/components/navigation';
import { MediaWatcherService } from 'app/core/media-watcher/media-watcher.service';
import { MenuService } from 'app/core/services/menu.service';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector     : 'dense-layout',
  templateUrl  : './dense.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone   : true,
  // eslint-disable-next-line max-len
  imports      : [FullscreenComponent, LanguagesComponent, MatButtonModule, MatIconModule, NgIf, NotificationsComponent, RouterOutlet, SearchComponent, UserComponent, VerticalNavigationComponent]
})
export class DenseLayoutComponent implements OnInit, OnDestroy
{
  isScreenSmall: boolean;
  navigation: Navigation;
  navigationAppearance: 'default' | 'dense' = 'dense';
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _mediaWatcherService: MediaWatcherService,
    private _menuService: MenuService,
    private _navigationService: NavigationService,
    private _router: Router
  )
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  public ngOnInit(): void
  {
    // Subscribe to navigation data
    this._menuService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) =>
      {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._mediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({matchingAliases}) =>
      {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  public ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void
  {
    // Get the navigation
    const navigation = this._navigationService.getComponent<VerticalNavigationComponent>(name);

    if (navigation)
    {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  /**
   * Toggle the navigation appearance
   */
  toggleNavigationAppearance(): void
  {
    this.navigationAppearance = (this.navigationAppearance === 'default' ? 'dense' : 'default');
  }
}
