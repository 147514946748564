import { APP_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { provideTransloco, Translation, TranslocoService } from '@ngneat/transloco';
import { MessageService } from '@progress/kendo-angular-l10n';
import { LocalStorageService } from 'app/core/storage/local-storage.service';
import { KendoMessageService } from 'app/core/translations/kendo-message.service';
import { TranslocoHttpLoader } from 'app/core/translations/transloco.http-loader';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

export const provideTranslations = (): Array<Provider | EnvironmentProviders> =>
{
  return [
    provideTransloco({  // Transloco Config
      config: {
        availableLangs      : [
          {
            id   : 'es',
            label: 'Castellano'
          },
          {
            id   : 'ca',
            label: 'Català'
          }
        ],
        defaultLang         : 'es',
        fallbackLang        : 'es',
        reRenderOnLangChange: true,
        prodMode            : environment.production
      },
      loader: TranslocoHttpLoader
    }),
    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide   : APP_INITIALIZER,
      deps      : [LocalStorageService],
      useFactory: (localStorageService: LocalStorageService): () => Promise<Translation> => {
        const translocoService = inject(TranslocoService);
        const defaultLang = localStorageService.language ?? translocoService.getDefaultLang();
        translocoService.setActiveLang(defaultLang);
        return () => lastValueFrom(translocoService.load(defaultLang));
      },
      multi   : true,
    },
    {
      provide: MessageService,
      useClass: KendoMessageService
    }
  ];
};
