import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { OfficialBodyLogin } from 'app/core/core.types';
import { OfficialBodyLoginService } from 'app/core/services/official-body-login.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector       : 'user',
  templateUrl    : './user.component.html',
  encapsulation  : ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs       : 'user',
  standalone     : true,
  imports        : [MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, NgClass, NgIf, TranslocoPipe]
})
export class UserComponent implements OnInit, OnDestroy
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_showAvatar: BooleanInput;

  @Input() showAvatar: boolean = true;
  user: OfficialBodyLogin;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: OfficialBodyLoginService
  )
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    // Subscribe to user changes
    this._userService.officialBodyLogin$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: OfficialBodyLogin) => {
        this.user = user;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign out
   */
  public signOut(): void
  {
    this._router.navigate(['/sign-out']);
  }

  /**
   * Profile
   */
  public profile(): void
  {
     this._router.navigate(['/profile']);
   }

   /**
    * Settings
    */
    public settings(): void
    {
    this._router.navigate(['/settings']);
    }
}
