import { Inject, Injectable } from '@angular/core';
import { AppConfig } from 'app/app.settings';
import { ASISTO_APP_CONFIG } from 'app/core/config/config.constants';
import { LocalStorageService } from 'app/core/storage';
import { merge } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigService
{

  // -----------------------------------------------------------------------------------------------------
  // @ Properties
  // -----------------------------------------------------------------------------------------------------

  /** this variable is used to keep the configuration always updated */
  private _config: BehaviorSubject<AppConfig>;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * Constructor
   */
  constructor(
    @Inject(ASISTO_APP_CONFIG) config: AppConfig,
    private _localStorageService: LocalStorageService
  )
  {
    this._config = new BehaviorSubject(config);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter for the config object, stores some user preferences configuration values in the local
   * storage, like the UI scheme color or the preferred language.
   */
  get config$(): Observable<AppConfig>
  {
    return this._config.asObservable();
  }
  /**
   * Getter for the config object.
   */
  set config(value: AppConfig)
  {
    // Merge the new config over to the current config
    const config = merge({}, this._config.getValue(), value);

    // Updates the local storage (scheme)
    if (config.scheme)
    {
      this._localStorageService.scheme = config.scheme;
    }
    else
    {
      this._localStorageService.removeScheme();
    }

    // Updates the local storage (language)
    if (config.language)
    {
      this._localStorageService.language = config.language;
    }
    else
    {
      this._localStorageService.removeLanguage();
    }

    // Execute the observable
    this._config.next(config);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method reset
   * Resets the config to the default
   */
  public reset(): void
  {
    // Set the config
    this._config.next(this.config);
  }

}
