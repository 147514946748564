import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { authInterceptor, AuthService } from 'app/core/auth';

/**
 * @function provideAuth
 * This function is used to intercept all requests in order to check if the user token is valid,
 * and if so, for putting it into the Authorization header of every request. If the token has
 * expired but the user has remembered the credentials, the renews the token.
 * @returns {Array<Provider | EnvironmentProviders>} the array of providers that provide the
 * authorization.
 */
export const provideAuth = (): Array<Provider | EnvironmentProviders> =>
{
  return [
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide : ENVIRONMENT_INITIALIZER,
      useValue: () => inject(AuthService),
      multi   : true
    }
  ];
};
