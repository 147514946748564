import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { DateAdapter, MATERIAL_SANITY_CHECKS, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading, withRouterConfig } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { appRoutes } from 'app/app.routes';
import { APP_CONFIG } from 'app/app.settings';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideCore } from 'app/core/core.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTranslations } from 'app/core/translations/transloco.provider';
import { getLocalePaginatorIntl } from 'app/shared/lib/localization-util';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideRouter(appRoutes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
      withRouterConfig({
        paramsInheritanceStrategy: 'emptyOnly',
        urlUpdateStrategy: 'eager'
      })
    ),
    {
      provide : MAT_DATE_FORMATS,
      useValue: {
        parse  : {
          dateInput: 'D',
        },
        display: {
          dateInput         : 'DDD',
          monthYearLabel    : 'LLL yyyy',
          dateA11yLabel     : 'DD',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },
    {
      provide : MAT_DATE_LOCALE, // Use 'spanish' locale by default for Angular Material components
      useValue: window.navigator.language
    },
    {
      provide : MAT_FORM_FIELD_DEFAULT_OPTIONS, // Use the 'fill' appearance on Angular Material form fields by default
      useValue: { appearance: 'fill' /*, subscriptSizing: 'dynamic'*/ } // uncomment this line for dynamic height in mat-error
    },
    {
      provide : MAT_LUXON_DATE_ADAPTER_OPTIONS, // Luxon DateTime Adapter for Angular Material components
      useValue: { useUtc: true }
    },
    {
      provide : MATERIAL_SANITY_CHECKS, // Disable 'theme' sanity check
      useValue: { doctype: true, theme: false, version: true }
    },
    {
      provide : DateAdapter,
      useClass: LuxonDateAdapter,
      //   useValue: new (class MondayFirstDayOfWeekDateAdapter extends LuxonDateAdapter {
      //   public getFirstDayOfWeek(): number { return 1; }
      //   })(),
      //   deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    provideTranslations(),  // Transloco and Kendo config
    {
      provide: MatPaginatorIntl,
      useFactory: getLocalePaginatorIntl,
      deps: [TranslocoService]
    },
    provideAuth(),
    provideIcons(),
    provideCore(APP_CONFIG)
  ],
};
