import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoPipe } from '@ngneat/transloco';
import { HttpRequestService } from 'app/core/http-request/http-request.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector       : 'footer',
  templateUrl    : './footer.component.html',
  encapsulation  : ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs       : 'footer',
  standalone     : true,
  imports        : [MatProgressSpinnerModule, NgIf, TranslocoPipe]
})
export class FooterComponent implements OnInit, OnDestroy
{

  /** This objects is used to know when to show/hide the loading spinner */
  public loading: boolean;
  /** This variable is used to unsubscribe from the HttpRequestService */
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private _httpRequestService: HttpRequestService,
    private _changeDetectorRef: ChangeDetectorRef
  )
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number
  {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  public ngOnInit(): void
  {
    this._httpRequestService.loading$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        this.loading = value;
        this._changeDetectorRef.detectChanges();
      });
  }

  /**
   * @method ngOnDestroy
   * Frees the subscription made in the component.
   */
  public ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
