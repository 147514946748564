import { NgFor, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AvailableLangs, LangDefinition, TranslocoService } from '@ngneat/transloco';
import { Language } from 'app/app.settings';
import { NavigationService } from 'app/components/navigation';
import { ConfigService } from 'app/core/config/config.service';
import { Subject } from 'rxjs';

@Component({
    selector       : 'languages',
    templateUrl    : './languages.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'languages',
    standalone     : true,
    imports        : [MatButtonModule, MatMenuModule, NgFor, NgTemplateOutlet]
})
export class LanguagesComponent implements OnInit, OnDestroy
{
  /** List of available languages */
  public availableLanguages: AvailableLangs;
  /** Variable that contains the currently active language */
  public activeLanguage: Language;
  /** Object containing a relation between the languages and the corresponding country */
  public flagCodes: { [flag: string]: string };

  /** This variable is used as a flag to close all the open subscriptions in the component */
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
      private _changeDetectorRef: ChangeDetectorRef,
      private _configService: ConfigService,
      private _navigationService: NavigationService,
      private _translocoService: TranslocoService
  )
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    // Get the available languages from transloco
    this.availableLanguages = this._translocoService.getAvailableLangs();

    // Subscribe to language changes
    this._translocoService.langChanges$.subscribe((activeLanguage) => {
      // Get the active lang
      this.activeLanguage = activeLanguage as Language;
      // Set the config language value
      this._configService.config = { language: this.activeLanguage };
      // Update the navigation
      //this._updateNavigation(activeLang);
    });

    // Set the country iso codes for languages for flags
    this.flagCodes = {
      'es': 'ES',
      'ca': 'ES'
    };
  }

  /**
   * @method ngOnDestroy
   * Frees the subscriptions made in the component.
   */
  public ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the active lang
   *
   * @param language
   */
  public setActiveLanguage(language: string): void
  {
    // Set the active lang
    this._translocoService.setActiveLang(language);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  public trackByFn(index: number, item: string | LangDefinition): string | number
  {
    return (typeof item === 'string' ? item : item.id) || index;
  }

//   // -----------------------------------------------------------------------------------------------------
//   // @ Private methods
//   // -----------------------------------------------------------------------------------------------------

//   /**
//    * Update the navigation
//    *
//    * @param lang
//    * @private
//    */
//   private _updateNavigation(lang: string): void
//   {
//     // For the demonstration purposes, we will only update the Dashboard names
//     // from the navigation but you can do a full swap and change the entire
//     // navigation data.
//     //
//     // You can import the data from a file or request it from your backend,
//     // it's up to you.

//     // Get the component -> navigation data -> item
//     const navComponent = this._navigationService.getComponent<VerticalNavigationComponent>('mainNavigation');

//     // Return if the navigation component does not exist
//     if (!navComponent )
//     {
//       return null;
//     }

//     // Get the flat navigation data
//     const navigation = navComponent.navigation;

//     // Get the Project dashboard item and update its title
//     const projectDashboardItem = this._navigationService.getItem('dashboards.project', navigation);
//     if (projectDashboardItem )
//     {
//     this._translocoService.selectTranslate('Project').pipe(take(1))
//       .subscribe((translation) => {
//         // Set the title
//         projectDashboardItem.title = translation;
//         // Refresh the navigation component
//         navComponent.refresh();
//       });
//     }

//     // Get the Analytics dashboard item and update its title
//     const analyticsDashboardItem = this._navigationService.getItem('dashboards.analytics', navigation);
//     if (analyticsDashboardItem )
//     {
//       this._translocoService.selectTranslate('Analytics').pipe(take(1))
//         .subscribe((translation) => {
//           // Set the title
//           analyticsDashboardItem.title = translation;
//           // Refresh the navigation component
//           navComponent.refresh();
//         });
//     }
//   }
}
