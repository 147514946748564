import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AppConfig, Credentials, HTTP_HEADERS } from 'app/app.settings';
import { ConfigService } from 'app/core/config/config.service';
import { OfficialBodyLogin } from 'app/core/core.types';
import { LocalStorageService } from 'app/core/storage';
import { isTokenExpired } from 'app/shared/lib/auth-utils';
import { Buffer } from 'buffer';
import { Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy
{

  // -----------------------------------------------------------------------------------------------------
  // @ Properties
  // -----------------------------------------------------------------------------------------------------

  /** Contains the (current) configuration */
  private _config: AppConfig;
  /** Used to free the subscriptions made for the current service */
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * @constructor
   * Initializes the authentication service, by subscribing to the configuration service
   * changes.
   * @param _configService > the service that allows to read the configuration.
   * @param _httpClient > the http client service to perform requests to the backend.
   * @param _localStorageService > a service to manage data into the local storage.
   * @param _translocoService
   */
  constructor(
    private _configService: ConfigService,
	private _httpClient: HttpClient,
    private _localStorageService: LocalStorageService
  )
  {
    this._configService.config$
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((config: AppConfig) => this._config = config);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Gets the access token required for authenticated requests to the web api.
   */
  get accessToken(): string
  {
	return this._localStorageService.accessToken;
  }

  /**
   * Gets the currently logged user.
   */
  get officialBodyLogin(): OfficialBodyLogin
  {
	return this._localStorageService.officialBodyLogin;
  }

  /**
   * Sets the access token required for authenticated requests to the web api.
   */
  set accessToken(token: string)
  {
	this._localStorageService.accessToken = token;
  }

  /**
   * Sets the currently logged user.
   */
  public set officialBodyLogin(officialBodyLogin: OfficialBodyLogin)
  {
	this._localStorageService.officialBodyLogin = officialBodyLogin;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method ngOnDestroy
   * Frees the subscriptions made in the service.
   */
  public ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method canRefreshToken
   * Checks if it is possible to get a new token using the locally stored credentials.
   * @returns {boolean} true if there are credentials stored for refresing the token, false
   * otherwise.
   */
  public canRefreshToken(): boolean
  {
    return !!this._localStorageService.credentials;
  }

  /**
   * @method forgotPassword
   * Sends an email to allow the user reset their password.
   * @param email > the email associated to the user account.
   */
  public forgotPassword(email: string): Observable<HttpResponse<void>>
  {
    return this._httpClient.post<HttpResponse<void>>(`${this._config.authApi}/login/external/forgot-password`, JSON.stringify(email), HTTP_HEADERS.applicationJson);
  }

  /**
   * @method hasValidToken
   * Check if there is any valid token in the local storage.
   * @returns true if there is any valid (unexpired) token in local storage.
   */
  public hasValidToken(): boolean
  {
    return this.accessToken && !isTokenExpired(this.accessToken);
  }

  /**
   * @method isAuthenticated
   * Check the authentication status (is there any user logged in?).
   * @returns true if some user is/has logged in, false otherwise.
   */
  public isAuthenticated(): boolean
  {
	// Checks the access token availability
	return !!this.officialBodyLogin;
  }

  /**
   * @method refreshToken
   * Tries to refresh the access token by using the 'remember me' data stored in the local storage
   * (if exists such data).
   * @returns {string} the token when a new token is retrieved.
   */
  public refreshToken(): Observable<string>
  {
    // Renews the token by using the credentials stored in the local storage
    return this._httpClient.post<{ TokenValue: string }>(`${this._config.authApi}/login/external/token`, this._localStorageService.getCredentialsAsParameter())
    .pipe(
      switchMap((response) =>
      {
        // Store the access token in the local storage and returns the response
        return of(this.accessToken = response.TokenValue);
      })
    );
  }

  /**
   * @method resetPassword
   * Resets the user password. If needed, updates the password of the credentials cookie.
   * @param password > the new password
   * @param code > the security code that identifies uniquely the change password reset
   * request.
   */
  public resetPassword(password: string, code: string): Observable<HttpResponse<void>>
  {
    return this._httpClient.post<HttpResponse<void>>(`${this._config.authApi}/login/external/reset-password`,
    {
      Password: Buffer.from(password, 'binary').toString('base64'),
      Code: code
    })
    .pipe(
      tap(
      {
        next: () => this._localStorageService.changePassword(password)
      })
    );
  }

  /**
   * @method signIn
   * Gets the JWT token
   * @param credentials > the credentials of the user to sign in
   */
  public signIn(credentials: { username: string; password: string; rememberMe: boolean }): Observable<object>
  {
    return this._httpClient.post<{ TokenValue: string, OfficialBodyLogin?: OfficialBodyLogin }>(`${this._config.authApi}/login/external/token`,
    {
      Username: credentials.username,
      Password: Buffer.from(credentials.password, 'binary').toString('base64')
    })
    .pipe(
      switchMap((response) =>
      {
        // Store the access token in the local storage
        this.accessToken = response.TokenValue;
        // Store the user on the local storage
        this._localStorageService.officialBodyLogin = response.OfficialBodyLogin;

        if (credentials.rememberMe)
        {
          // Creates a cookie to store the credentials (or deletes it if requested so), used to refresh the token
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          this._localStorageService.credentials = (({ rememberMe, ...value }): Credentials => value)(credentials); // removes rememberMe property
        }
        else
        {
          // Removes the credentials from the persistent storage
          this._localStorageService.removeCredentials();
        }

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * @method signOut
   * Sign out
   */
  public signOut(): Observable<boolean>
  {
	// Remove the access token and logged in user from the local storage
	this._localStorageService.removeAccessToken();
    this._localStorageService.removeOfficialBodyLogin();

	// Return the observable
	return of(true);
  }

}
