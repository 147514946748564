import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoPipe } from '@ngneat/transloco';
import { NavigationService } from 'app/components/navigation/navigation.service';
import { NavigationItem } from 'app/components/navigation/navigation.types';
import { VerticalNavigationBasicItemComponent } from 'app/components/navigation/vertical/components/basic/basic.component';
import { VerticalNavigationCollapsableItemComponent } from 'app/components/navigation/vertical/components/collapsable/collapsable.component';
import { VerticalNavigationDividerItemComponent } from 'app/components/navigation/vertical/components/divider/divider.component';
import { VerticalNavigationSpacerItemComponent } from 'app/components/navigation/vertical/components/spacer/spacer.component';
import { VerticalNavigationComponent } from 'app/components/navigation/vertical/vertical.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector       : 'vertical-navigation-group-item',
  templateUrl    : './group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone     : true,
  // eslint-disable-next-line max-len
  imports        : [MatIconModule, NgClass, NgFor, NgIf, TranslocoPipe, VerticalNavigationBasicItemComponent, VerticalNavigationCollapsableItemComponent, VerticalNavigationDividerItemComponent, forwardRef(() => VerticalNavigationGroupItemComponent), VerticalNavigationSpacerItemComponent]
})
export class VerticalNavigationGroupItemComponent implements OnInit, OnDestroy
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_autoCollapse: BooleanInput;

  @Input() autoCollapse: boolean;
  @Input() item: NavigationItem;
  @Input() name: string;

  private _verticalNavigationComponent: VerticalNavigationComponent;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _navigationService: NavigationService
  )
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    // Get the parent navigation component
    this._verticalNavigationComponent = this._navigationService.getComponent(this.name);

    // Subscribe to onRefreshed on the navigation component
    this._verticalNavigationComponent.onRefreshed.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(() => {

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: NavigationItem): string | number
  {
    return item.id || index;
  }
}
