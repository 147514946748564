import { Inject, Injectable } from '@angular/core';
import { Credentials, Language, Scheme } from 'app/app.settings';
import { STORAGE_KEYS, WINDOW } from 'app/core/storage';
import { StorageService } from 'app/core/storage/storage.service';
import { OfficialBodyLogin } from 'app/core/core.types';
import { Buffer } from 'buffer';
import { Observable } from 'rxjs';

/**
 * Local storage service, used for persist application data in observable key value pairs.
 */
 @Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends StorageService
{

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * @constructor
   * Constructor with service injection.
   * @param _window > the window object.
   */
  constructor(@Inject(WINDOW) private _window: Window)
  {
    super(_window.localStorage);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Gets the access token required for authenticated requests to the web api.
   */
  public get accessToken(): string
  {
    return this.get<string>(STORAGE_KEYS.accessToken) ?? '';
  }

  /**
   * Gets the language to use in the UI.
   */
  public get credentials(): Credentials
  {
    return this.get<Credentials>(STORAGE_KEYS.credentials);
  }

  /**
   * Gets the language to use in the UI.
   */
  public get language(): Language
  {
    return this.get<Language>(STORAGE_KEYS.language);
  }

  /**
   * Gets the currently logged user.
   */
  public get officialBodyLogin(): OfficialBodyLogin
  {
    return this.get<OfficialBodyLogin>(STORAGE_KEYS.officialBodyLogin);
  }

  /**
   * Gets an observable for the official body login logged into the application.
   */
  public get officialBodyLogin$(): Observable<OfficialBodyLogin>
  {
    return this.watch<OfficialBodyLogin>(STORAGE_KEYS.officialBodyLogin);
  }

  /**
   * Gets the default scheme (color) for the UI.
   */
  public get scheme(): Scheme
  {
    return this.get<Scheme>(STORAGE_KEYS.scheme);
  }

  /**
   * Sets the access token required for authenticated requests to the web api.
   */
  public set accessToken(value: string)
  {
    this.set<string>(STORAGE_KEYS.accessToken, value);
  }

  /**
   * Sets the access token required for authenticated requests to the web api.
   */
  public set credentials(value: Credentials)
  {
    this.set<Credentials>(STORAGE_KEYS.credentials, value);
  }

  /**
   * Sets the language to use the UI.
   */
  public set language(value: Language)
  {
    this.set<Language>(STORAGE_KEYS.language, value);
  }

  /**
   * Sets the currently logged user.
   */
  public set officialBodyLogin(value: OfficialBodyLogin)
  {
    this.set<OfficialBodyLogin>(STORAGE_KEYS.officialBodyLogin, value);
  }

  /**
   * Sets the default scheme (color) for the UI.
   */
  public set scheme(value: Scheme)
	{
		this.set<Scheme>(STORAGE_KEYS.scheme, value);
	}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method changePassword
   * Changes the password of the stored credentials (if there are credentials stored).
   * @param password > the new password to register for the official body login.
   */
  public changePassword(password: string): void
  {
    if (this.has(STORAGE_KEYS.credentials))
    {
      const credentials = this.credentials;
      credentials.password = password;
      this.credentials = credentials;
    }
  }

  /**
   * @method changeRecoverEmail
   * Changes the recover password email address of the currently logged in official body user.
   * @param email > the new email to register as the recover password email.
   */
  public changeRecoverEmail(email: string): void
  {
    if (this.has(STORAGE_KEYS.officialBodyLogin))
    {
      const officialBodyLogin = this.officialBodyLogin;
      officialBodyLogin.Email = email;
      this.officialBodyLogin = officialBodyLogin;
    }
  }

  /**
   * @method asParameter
   * Retrieves the credentials stored in the cookie as an object that can be used as a Web API
   * parameters.
   * Remark that the password is stored as plain text string in the local storage.
   */
  public getCredentialsAsParameter(): { Username: string; Password: string } | null {
    const credentials = this.credentials;
    return credentials
      ? {
        Username: credentials.username,
        Password: Buffer.from(credentials.password, 'binary').toString('base64')
      }
      : null;
  }

  /**
   * @method removeAccessToken
   * Removes the access token from the local storage.
   */
  public removeAccessToken(): void
  {
    this.remove(STORAGE_KEYS.accessToken);
  }

  /**
   * @method removeCredentials
   * Removes the credentials from the local storage.
   */
  public removeCredentials(): void
  {
    this.remove(STORAGE_KEYS.credentials);
  }

  /**
   * @method removeLanguage
   * Removes the language from the local storage.
   */
  public removeLanguage(): void
  {
    this.remove(STORAGE_KEYS.language);
  }

  /**
   * @method removeOfficialBodyLogin
   * Removes the official body login user from the local storage.
   */
  public removeOfficialBodyLogin(): void
  {
    this.remove(STORAGE_KEYS.officialBodyLogin);
  }

  /**
   * @method removeScheme
   * Removes the scheme from the local storage.
   */
  public removeScheme(): void
  {
    this.remove(STORAGE_KEYS.scheme);
  }

}
