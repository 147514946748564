import { IsActiveMatchOptions } from '@angular/router';
import { HorizontalNavigationComponent } from 'app/components/navigation/horizontal/horizontal.component';
import { VerticalNavigationComponent } from 'app/components/navigation/vertical/vertical.component';

/**
 * @interface Navigation
 * @version 1.0
 * Interface used to pass data to a navigation (routing) service. Each property is a different
 * layout, and contains the collection of navigable items for that layout.
 */
export interface Navigation
{
  compact: NavigationItem[];
  default: NavigationItem[];
  futuristic: NavigationItem[];
  horizontal: NavigationItem[];
}

export type NavigationComponent =
  | VerticalNavigationComponent
  | HorizontalNavigationComponent;

export interface NavigationItem
{
  id?: string;
  title?: string;
  subtitle?: string;
  type:
    | 'aside'
    | 'basic'
    | 'collapsable'
    | 'divider'
    | 'group'
    | 'spacer';
  hidden?: (item: NavigationItem) => boolean;
  active?: boolean;
  disabled?: boolean;
  tooltip?: string;
  link?: string;
  externalLink?: boolean;
  target?:
    | '_blank'
    | '_self'
    | '_parent'
    | '_top'
    | string;
  exactMatch?: boolean;
  isActiveMatchOptions?: IsActiveMatchOptions;
  function?: (item: NavigationItem) => void;
  classes?: {
    title?: string;
    subtitle?: string;
    icon?: string;
    wrapper?: string;
  };
  icon?: string;
  badge?: {
    title?: string;
    classes?: string;
  };
  children?: NavigationItem[];
  meta?: unknown;
}

export class ScrollbarGeometry
{
  public x: number;
  public y: number;

  public w: number;
  public h: number;

  constructor(x: number, y: number, w: number, h: number)
  {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
  }
}

export class ScrollbarPosition
{
  public x: number | 'start' | 'end';
  public y: number | 'start' | 'end';

  constructor(x: number | 'start' | 'end', y: number | 'start' | 'end')
  {
    this.x = x;
    this.y = y;
  }
}

export type VerticalNavigationAppearance =
  | 'default'
  | 'compact'
  | 'dense'
  | 'thin';

export type VerticalNavigationMode =
  | 'over'
  | 'side';

export type VerticalNavigationPosition =
  | 'left'
  | 'right';
