<!-- Navigation -->
<vertical-navigation
  class="dark bg-primary-900 text-white border-b print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.futuristic"
  [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container verticalNavigationHeader>
    <!-- Logo -->
    <a routerLink="/" class="no-underline cursor-default flex justify-center items-center h-24 p-6 pb-2">
      <svg
        class="w-38 mb-4 fill-current text-primary-200 mr-6"
        version="1.1"
        viewBox="0 0 260.6 80.2"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg">
        <path d="m46.6 0.7c-5.6-1.1-1.3 8-1.3 8 0.5 1.7 3.6 5.5 6.9 0.1s0-7-5.6-8.1z"/>
        <path d="m44.8 24.8c-10.1-2.3-18.8-3.3-25-1.6l8.8-20.4c1.3-2.9 5.4-2.9 6.7 0l9.5 22z"/>
        <path d="m48.9 78.9-11.3-5.2c0.2-0.1 0.4-0.3 0.6-0.4 1.6-1.1 2.9-2.2 4-3.3 0 0 7.5-6.3 13.1-13.7 0.6-0.8 1.2-1.7 1.8-2.5l5.2 12c3.8 8.4-4.9 17-13.4 13.1z"/>
        <path d="m48.2 30.5c-0.4-0.1-0.8-0.2-1.1-0.3-1-0.2-1.9-0.4-2.8-0.7l-2.7-0.6c-0.9-0.2-1.7-0.3-2.6-0.5-0.7-0.1-1.4-0.3-2.1-0.3-0.7-0.1-1.3-0.2-2-0.3-0.3-0.1-0.7-0.1-1-0.1-4.1-0.5-7.7-0.7-11-0.2-0.3 0-0.7 0.1-1 0.1-0.7 0.1-1.3 0.2-1.8 0.4-0.9 0.2-1.7 0.5-2.5 0.8-0.1 0-0.2 0.1-0.3 0.1l-8.8 20.3v0.1l-7.1 16.4c-3.7 8.5 5.1 17.1 13.5 13.2 0.9-0.4 1.8-0.8 2.7-1.3 2.6-1.2 5.2-2.4 7.9-3.6 2.1-1 4.4-1.8 6.4-2.9h-0.1s0.1-0.1 0.2-0.1c2.6-1.5 5.9-4.5 5.9-4.5s0 0 0.1-0.1 0.2-0.2 0.4-0.3c0.8-0.7 2.7-2.3 4.8-4.5l0.8-0.8c2.3-2.3 4.9-5.1 7.2-8.1 6.8-8.7 10.8-18.9-3-22.2z"/>
        <path d="m99.4 22.5h7.6l16.1 37.9h-8.6l-3.4-8.4h-16l-3.4 8.4h-8.4l16.1-37.9zm8.7 22.1-5-12.2-5 12.2h10z"/>
        <path d="m140 60.9c-2 0-4.1-0.3-6.3-1-2.1-0.7-4.2-1.8-6.1-3.3l3.5-5.4c1.6 1.1 3.1 2 4.7 2.6s3 0.9 4.4 0.9c1.2 0 2-0.2 2.6-0.6s0.8-0.9 0.8-1.6v-0.1c0-0.4-0.2-0.8-0.5-1.1s-0.8-0.6-1.3-0.8c-0.6-0.2-1.2-0.5-1.9-0.7s-1.5-0.4-2.3-0.7c-1-0.3-2-0.6-3-1s-1.9-0.9-2.8-1.6c-0.8-0.7-1.5-1.5-2-2.4-0.5-1-0.8-2.1-0.8-3.5v-0.1c0-1.5 0.3-2.8 0.8-3.9 0.6-1.1 1.3-2.1 2.3-2.9 0.9-0.8 2.1-1.4 3.3-1.8 1.3-0.4 2.6-0.6 4.1-0.6 1.8 0 3.7 0.3 5.6 0.9s3.6 1.4 5.2 2.4l-3.1 5.6c-1.4-0.8-2.8-1.5-4.2-2s-2.6-0.7-3.6-0.7-1.8 0.2-2.3 0.6-0.8 0.9-0.8 1.4v0.1c0 0.4 0.2 0.7 0.5 1s0.8 0.6 1.3 0.9 1.2 0.5 1.9 0.8 1.4 0.5 2.2 0.8c1 0.3 2 0.7 3.1 1.2 1 0.4 2 1 2.8 1.6s1.5 1.4 2 2.4c0.5 0.9 0.8 2.1 0.8 3.4v0.1c0 1.6-0.3 3-0.8 4.2-0.6 1.2-1.3 2.1-2.3 2.9s-2.2 1.3-3.5 1.7-2.7 0.3-4.3 0.3z"/>
        <path d="m158.9 21.1h8.6v7.3h-8.6v-7.3zm0.2 10.5h8.2v28.8h-8.2v-28.8z"/>
        <path d="m185.9 60.9c-2 0-4.1-0.3-6.3-1-2.1-0.7-4.2-1.8-6.1-3.3l3.5-5.4c1.6 1.1 3.1 2 4.7 2.6s3 0.9 4.4 0.9c1.2 0 2-0.2 2.6-0.6s0.8-0.9 0.8-1.6v-0.1c0-0.4-0.2-0.8-0.5-1.1s-0.8-0.6-1.3-0.8c-0.6-0.2-1.2-0.5-1.9-0.7s-1.5-0.4-2.3-0.7c-1-0.3-2-0.6-3-1s-1.9-0.9-2.8-1.6c-0.8-0.7-1.5-1.5-2-2.4-0.5-1-0.8-2.1-0.8-3.5v-0.1c0-1.5 0.3-2.8 0.8-3.9 0.6-1.1 1.3-2.1 2.3-2.9 0.9-0.8 2.1-1.4 3.3-1.8 1.3-0.4 2.6-0.6 4.1-0.6 1.8 0 3.7 0.3 5.6 0.9s3.6 1.4 5.2 2.4l-3.1 5.6c-1.4-0.8-2.8-1.5-4.2-2s-2.6-0.7-3.6-0.7-1.8 0.2-2.3 0.6-0.8 0.9-0.8 1.4v0.1c0 0.4 0.2 0.7 0.5 1s0.8 0.6 1.3 0.9 1.2 0.5 1.9 0.8 1.4 0.5 2.2 0.8c1 0.3 2 0.7 3.1 1.2 1 0.4 2 1 2.8 1.6s1.5 1.4 2 2.4c0.5 0.9 0.8 2.1 0.8 3.4v0.1c0 1.6-0.3 3-0.8 4.2-0.6 1.2-1.3 2.1-2.3 2.9s-2.2 1.3-3.5 1.7-2.8 0.3-4.3 0.3z"/>
        <path d="m215.7 60.8c-1.3 0-2.4-0.1-3.4-0.4s-1.9-0.7-2.6-1.4-1.3-1.6-1.7-2.7-0.6-2.5-0.6-4.2v-13.6h-3.4v-7h3.4v-7.4h8.2v7.4h6.8v7h-6.8v12.3c0 1.9 0.9 2.8 2.6 2.8 1.4 0 2.8-0.3 4-1v6.6c-0.9 0.5-1.8 0.9-2.8 1.2-1.1 0.3-2.3 0.4-3.7 0.4z"/>
        <path d="m244.5 61c-2.2 0-4.3-0.4-6.2-1.2s-3.5-1.8-4.9-3.2c-1.4-1.3-2.5-2.9-3.3-4.7s-1.2-3.7-1.2-5.8v-0.1c0-2.1 0.4-4 1.2-5.8s1.9-3.4 3.3-4.8 3-2.4 4.9-3.2 4-1.2 6.2-1.2 4.3 0.4 6.2 1.2 3.5 1.8 4.9 3.2c1.4 1.3 2.5 2.9 3.3 4.7s1.2 3.8 1.2 5.8v0.1c0 2.1-0.4 4-1.2 5.8s-1.9 3.4-3.3 4.8-3 2.4-4.9 3.2c-1.8 0.8-3.9 1.2-6.2 1.2zm0.1-7c1.2 0 2.2-0.2 3.1-0.6s1.7-1 2.3-1.7 1.1-1.5 1.5-2.5c0.3-0.9 0.5-2 0.5-3v-0.2c0-1.1-0.2-2.1-0.5-3-0.4-0.9-0.9-1.8-1.6-2.5s-1.5-1.3-2.4-1.7-2-0.6-3.1-0.6c-1.2 0-2.2 0.2-3.1 0.6s-1.7 1-2.3 1.7-1.1 1.6-1.5 2.5c-0.3 0.9-0.5 2-0.5 3 0 1.1 0.2 2.1 0.5 3 0.4 0.9 0.9 1.8 1.6 2.5s1.5 1.3 2.4 1.7c1 0.5 2 0.8 3.1 0.8z"/>
      </svg>
    </a>
  </ng-container>
  <!-- Navigation footer hook -->
  <ng-container verticalNavigationFooter>
    <!-- User -->
    <div class="flex items-center w-full px-6 py-8 border-t">
      <user></user>
      <div class="flex flex-col w-full ml-4 overflow-hidden">
        <div class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden leading-normal text-current opacity-80">
          {{user.Username}}
        </div>
        <div class="w-full mt-0.5 text-sm whitespace-nowrap overflow-ellipsis overflow-hidden leading-normal text-current opacity-50">
          {{user.Email}}
        </div>
      </div>
    </div>
    <div class="absolute bottom-1 left-2"><span class="text-xs text-current opacity-40">v{{currentVersion}}</span></div>
  </ng-container>
</vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

  <!-- Header -->
  <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button
      class="mr-2"
      mat-icon-button
      (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
      <languages></languages>
      <fullscreen class="hidden md:block"></fullscreen>
      <search [appearance]="'bar'"></search>
      <!-- <notifications></notifications> -->
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
          Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <footer></footer>

</div>
