import packageJson from '../../package.json';

export const environment = {
    production: true,
    domain: 'asistosad.com',
    protocol: 'https',
    // domain: 'asistopre.clece.es',
    // protocol: 'http',
    appVersion: packageJson.version
};
