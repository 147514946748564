import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';
import { HorizontalNavigationBasicItemComponent } from 'app/components/navigation/horizontal/components/basic/basic.component';
import { HorizontalNavigationDividerItemComponent } from 'app/components/navigation/horizontal/components/divider/divider.component';
import { HorizontalNavigationComponent } from 'app/components/navigation/horizontal/horizontal.component';
import { NavigationService } from 'app/components/navigation/navigation.service';
import { NavigationItem } from 'app/components/navigation/navigation.types';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector       : 'horizontal-navigation-branch-item',
  templateUrl    : './branch.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone     : true,
  // eslint-disable-next-line max-len
  imports        : [NgIf, NgClass, MatMenuModule, NgTemplateOutlet, NgFor, HorizontalNavigationBasicItemComponent, forwardRef(() => HorizontalNavigationBranchItemComponent), HorizontalNavigationDividerItemComponent, MatTooltipModule, MatIconModule, TranslocoPipe],
})
export class HorizontalNavigationBranchItemComponent implements OnInit, OnDestroy
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_child: BooleanInput;

  @Input() child: boolean = false;
  @Input() item: NavigationItem;
  @Input() name: string;
  @ViewChild('matMenu', { static: true }) matMenu: MatMenu;

  private _horizontalNavigationComponent: HorizontalNavigationComponent;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _navigationService: NavigationService
  )
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    // Get the parent navigation component
    this._horizontalNavigationComponent = this._navigationService.getComponent(this.name);

    // Subscribe to onRefreshed on the navigation component
    this._horizontalNavigationComponent.onRefreshed.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(() => {

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Trigger the change detection
   */
  triggerChangeDetection(): void
  {
    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: NavigationItem): string | number
  {
    return item.id || index;
  }
}
