import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeES from '@angular/common/locales/es'; // angular translations (date pipe)
import localeCA from '@angular/common/locales/ca';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, LOCALE_ID, Provider } from '@angular/core';
import { AppConfig } from 'app/app.settings';
import { ASISTO_APP_CONFIG } from 'app/core/config/config.constants';
import { httpRequestInterceptor, HttpRequestService } from 'app/core/http-request';
import { MediaWatcherService } from 'app/core/media-watcher';
import { PlatformService } from 'app/core/platform';
import { SplashScreenService } from 'app/core/splash-screen';
import { LocalStorageService, SessionStorageService, WINDOW } from 'app/core/storage';
import { provideTitleStrategy } from 'app/core/titles/transloco-title-strategy.provider';

/**
 * App core provider
 */
export const provideCore = (config: AppConfig): Array<Provider | EnvironmentProviders> =>
{
  // registers the locales with the specified name (date pipe)
  registerLocaleData(localeES, 'es');
  registerLocaleData(localeCA, 'ca');

  const providers: Array<Provider | EnvironmentProviders> = [
    {
      provide : LOCALE_ID, // Mandatory for date/currency pipes
      useValue: window.navigator.language
    },
    {
      provide : WINDOW,  // Neeeded for the storage service
      useValue: window
    },
    {
      provide : ASISTO_APP_CONFIG,
      useValue: config ?? {},
    },
    provideHttpClient(withInterceptors([httpRequestInterceptor])),
    {
      provide : ENVIRONMENT_INITIALIZER,
      useValue: () => inject(HttpRequestService),
      multi   : true,
    },
    {
      provide : ENVIRONMENT_INITIALIZER,
      useValue: () => inject(MediaWatcherService),
      multi   : true,
    },
    {
      provide : ENVIRONMENT_INITIALIZER,
      useValue: () => inject(PlatformService),
      multi   : true,
    },
    {
      provide : ENVIRONMENT_INITIALIZER,
      useValue: () => inject(SplashScreenService),
      multi   : true,
    },
    {
      provide : ENVIRONMENT_INITIALIZER,
      useValue: () => inject(LocalStorageService),
      multi   : true,
    },
    {
      provide : ENVIRONMENT_INITIALIZER,
      useValue: () => inject(SessionStorageService),
      multi   : true,
    },
    provideTitleStrategy()
  ];
  return providers;
}
