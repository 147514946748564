import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { animations } from 'app/core/animations';
import { NavigationItem } from 'app/components/navigation/navigation.types';
import { NavigationService } from 'app/components/navigation/navigation.service';
import { HorizontalNavigationBasicItemComponent } from 'app/components/navigation/horizontal/components/basic/basic.component';
import { HorizontalNavigationBranchItemComponent } from 'app/components/navigation/horizontal/components/branch/branch.component';
import { HorizontalNavigationSpacerItemComponent } from 'app/components/navigation/horizontal/components/spacer/spacer.component';
import { randomId } from 'app/shared/lib/string-utils';

@Component({
  selector       : 'horizontal-navigation',
  templateUrl    : './horizontal.component.html',
  styleUrls      : ['./horizontal.component.scss'],
  animations     : animations,
  encapsulation  : ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs       : 'horizontalNavigation',
  standalone     : true,
  imports        : [NgFor, NgIf, HorizontalNavigationBasicItemComponent, HorizontalNavigationBranchItemComponent, HorizontalNavigationSpacerItemComponent]
})
export class HorizontalNavigationComponent implements OnChanges, OnInit, OnDestroy
{
  @Input() name: string = randomId();
  @Input() navigation: NavigationItem[];

  onRefreshed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _navigationService: NavigationService
  )
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void
  {
    // Navigation
    if ('navigation' in changes)
    {
      // Mark for check
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * On init
   */
  ngOnInit(): void
  {
    // Make sure the name input is not an empty string
    if (this.name === '')
    {
      this.name = randomId();
    }

    // Register the navigation component
    this._navigationService.registerComponent(this.name, this);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Deregister the navigation component from the registry
    this._navigationService.deregisterComponent(this.name);

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Refresh the component to apply the changes
   */
  refresh(): void
  {
    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Execute the observable
    this.onRefreshed.next(true);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: NavigationItem): string | number
  {
    return item.id || index;
  }
}
