import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth';
import { setToken } from 'app/shared/lib/auth-utils';
import { catchError, Observable, switchMap, throwError } from 'rxjs';

/**
 * @function authInterceptor
 * This function is used to intercept all requests in order to check if the user token is valid,
 * and if so, for putting it into the Authorization header of every request. If the token has
 * expired but the user has remembered the credentials, the renews the token.
 * @param request > the request to process
 * @param next > the next handler in the chain to process the request
 * @returns {Observable<HttpEvent<unknown>>} the result of the call to the next handler, after
 * having processed the request for analyzing the authorization.
 */
export const authInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{
  const authService = inject(AuthService);
  const router = inject(Router);

  // if the access token didn't expire, adds the Authorization header.
  // it won't add the Authorization header if the access token expired.
  // this will force the server to return a "401 Unauthorized" response
  // for the protected API routes which our response interceptor will
  // catch and delete the access token from the local storage while logging
  // the user out from the app.
  const newRequest = authService.hasValidToken()
    ? setToken(request, authService.accessToken)
    : request.clone();

  // sign out function
  const signOut = (): void => {
    // Signs out
    authService.signOut();
    // Reload the app, only if not in login page yet
    if (!router.url.startsWith('/sign-in'))
    {
      router.navigateByUrl('/sign-in');
    }
  };

  // Response
  return next(newRequest).pipe(
    catchError((error) =>
    {
      // Catch "401 Unauthorized" responses
      if (error instanceof HttpErrorResponse && error.status === 401)
      {
        if (authService.canRefreshToken())
        {
          // if there are credentials in the local storage, tries to get a new token
          return authService.refreshToken()
            .pipe(
               catchError((error) =>
               {
                 signOut();
                 return throwError(() => error);
               }),
               switchMap<string, Observable<HttpEvent<unknown>>>((token: string) =>
               {
                 return next(setToken(request, token));
               })
           );
        }
        // cannot refresh token
        signOut();
      }
      // Rest of errors (no 401)
      return throwError(() => error);
    })
  );
}
