import { Route } from '@angular/router';
import { authGuard } from 'app/core/auth/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { initialDataResolver } from 'app/app.resolvers';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    { path: '', pathMatch : 'full', redirectTo: 'dashboard' },

    // Redirect signed in user to the '/dashboard'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard' },

    // Auth routes for guests and sign-out
    {
      path: '',
      component: LayoutComponent,
      data: {
        layout: 'empty'
      },
      children: [
        { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
        { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
        { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') }
      ]
    },

    // Auth routes for (not) authenticated users
    {
      path: '',
      component: LayoutComponent,
      data: {
          layout: 'empty'
      },
      children: [
        { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') }
      ]
    },

    // Admin routes
    {
       path       : '',
      canActivate: [authGuard],
      canActivateChild: [authGuard],
      component  : LayoutComponent,
      data: {
        layout: 'futuristic'
      },
      resolve    : {
        initialData: initialDataResolver,
      },
      children   : [
        { path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.routes') },
        { path: 'info', loadChildren: () => import('app/modules/admin/info/info.routes') },
        { path: 'profile', loadChildren: () => import('app/modules/admin/profile/profile.routes') },
        { path: 'reports', loadChildren: () => import('app/modules/admin/reports/reports.routes') },
        { path: 'security', loadChildren: () => import('app/modules/admin/security/security.routes') },
        { path: 'settings', loadChildren: () => import('app/modules/admin/settings/settings.routes') },
        { path: 'users', loadChildren: () => import('app/modules/admin/users/users.routes') },
        { path: 'communications', loadChildren: () => import('app/modules/admin/communications/communications.routes') }
      ]
    }
];
