import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { HttpRequestService } from 'app/core/http-request/http-request.service';
import { reviveDates } from 'app/shared/lib/date-utils';
import { finalize, Observable, tap } from 'rxjs';

/**
 * @function httpRequestInterceptor
 * This interceptor manages the error handling and the loading service for all
 * the HTTP requests, catching all the http requests in order to inform the
 * presentation layer when a request has begun or finished,
 * @author jmgonzalezr
 * @version 2.0
 */
export const httpRequestInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{
  const httpRequestService = inject(HttpRequestService);

  // increments the number of pending requests
  httpRequestService.increment();
  // if (request.responseType === 'json') {
  //   const newHttpRequest = request.clone({
  //       responseType: 'text',
  //       body: JSON.stringify(request.body, dateReplacer)
  //   });
  return next(request)
    .pipe(
      // retry(1),
      tap(
        // this method handles a success in the response of the captured request. it parses the
        // Date objects in 8601 format and convert them to DateTime objects
        (event: HttpEvent<unknown>): void =>
        {
          if (event instanceof HttpResponse)
          {
            reviveDates((event as HttpResponse<HttpEvent<unknown>>).body);
          }
        }/*,
        // Allows to do something when received an error response
        (error: any): Observable<never> => EMPTY*/
      ),
      finalize(() => {                                                        // loading service
        // decrements the number of pending requests
        httpRequestService.decrement();
      })
    );

}
