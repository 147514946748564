import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { OfficialBodyLogin, WorkCenterConfiguration, WorkCenterConfigurationExternalWeb } from 'app/core/core.types';
import { AppConfig } from 'app/app.settings';
import { ConfigService } from 'app/core/config/config.service';
import { LocalStorageService } from 'app/core/storage/local-storage.service';
import { SessionStorageService } from 'app/core/storage/session-storage.service';
import { Buffer } from 'buffer';
import { map, Observable, Subject, takeUntil, tap  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfficialBodyLoginService implements OnDestroy
{

  // -----------------------------------------------------------------------------------------------------
  // @ Properties
  // -----------------------------------------------------------------------------------------------------

  /** Contains the (current) configuration */
  private _config: AppConfig;
  /** Used to free the subscriptions made for the current service */
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * @constructor
   * Subscribes to the config service in order to know the default API uri.
   * @param _sessionStorageService > this object allows to access to the session storage stored
   * properties.
   */
  constructor(
    private _configService: ConfigService,
    private _httpClient: HttpClient,
    private _localStorageService: LocalStorageService,
    private _sessionStorageService: SessionStorageService
  )
  {
    this._configService.config$
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((config: AppConfig) => this._config = config);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter observable for the logged in user
   */
  get officialBodyLogin$(): Observable<OfficialBodyLogin>
  {
    return this._localStorageService.officialBodyLogin$;
  }

  /**
   * Getter observable for the currently logged user (official body login) web configuration.
   */
  get webConfiguration$(): Observable<WorkCenterConfigurationExternalWeb>
  {
    return this._sessionStorageService.webConfiguration$;
  }

  /**
   * Getter observable for the currently logged user (official body login) workcenter configuration.
   */
  get workCenterConfiguration$(): Observable<WorkCenterConfiguration>
  {
    return this._sessionStorageService.workCenterConfiguration$;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method ngOnDestroy
   * Frees the subscriptions made in the service.
   */
  public ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  public get(): Observable<OfficialBodyLogin>
  {
    return this._httpClient.get<OfficialBodyLogin>(`${this._config.webApi}/officialbody-login/user`).pipe(
      tap((officialBodyLogin) => {
        this._localStorageService.officialBodyLogin = officialBodyLogin;
      })
    );
  }

  /**
   * @method getPasswordExpirationDays
   * Gets the number of the days of the currently logged in official body login password to expire.
   */
  public getPasswordExpirationDays(): Observable<number>
  {
    return this._httpClient.get<{ Days: number }>(`${this._config.webApi}/officialbody-login/password/expiration-days`).pipe(
      map(_ => _.Days)
    );
  }

  /**
   * @method getWebConfiguration
   * Gets the current web configuration
   */
  public getWebConfiguration(): Observable<WorkCenterConfigurationExternalWeb>
  {
    return this._httpClient.get<WorkCenterConfigurationExternalWeb>(`${this._config.webApi}/officialbody-login/configuration/web`).pipe(
      tap((webConfiguration) => {
        this._sessionStorageService.webConfiguration = webConfiguration;
      })
    );
  }

  /**
   * @method getWorkCenterConfiguration
   * Gets the workcenter configuration
   */
  public getWorkCenterConfiguration(): Observable<WorkCenterConfiguration>
  {
    return this._httpClient.get<WorkCenterConfiguration>(`${this._config.webApi}/officialbody-login/configuration/workcenter`).pipe(
      tap((workCenterConfiguration) => {
        this._sessionStorageService.workCenterConfiguration = workCenterConfiguration;
      })
    );
  }

  /**
   * @method updateSecurityData
   * Updates the user security data: recover password email (and password).
   * @param data > an object with the followinf fields:
   *   id: identifier of the official body login record to update.
   *   password: current password of the official body login entity.
   *   newPassword: new password (optional).
   *   recoverPasswordEmail: the email to which send a new password when the active one has been
   *   lost or forgotten.
   */
  public updateSecurityData(data: { Id: number; password: string; newPassword: string; recoverPasswordEmail: string }): Observable<OfficialBodyLogin>
  {
    return this._httpClient.patch<OfficialBodyLogin>(`${this._config.webApi}/officialbody-login/${data.Id}/security`,
      {
        Password: Buffer.from(data.password, 'binary').toString('base64'),
        NewPassword: Buffer.from(data.newPassword, 'binary').toString('base64'),
        Email: data.recoverPasswordEmail
      }).pipe(
        tap(() => {
          // Updates the password stored in the local storage (credentials) and the recover email
          this._localStorageService.changePassword(data.newPassword);
          this._localStorageService.changeRecoverEmail(data.recoverPasswordEmail);
        })
      );
  }

}
