import { Injectable, OnDestroy } from '@angular/core';
import { dateReplacer, dateReviver } from 'app/shared/lib/date-utils';
import { isNil } from 'lodash-es';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class StorageService implements OnDestroy
{
  // -----------------------------------------------------------------------------------------------------
  // @ Properties
  // -----------------------------------------------------------------------------------------------------

  /** The storage medium used to save the data */
  private _storage: Storage;
  /** Collection of observables to watch local storage changes */
  private _subjects: Map<string, ReplaySubject<unknown>>;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * @constructor
   * Initializes the authentication service, by subscribing to the configuration service
   * changes.
   */
  constructor(storage: Storage)
  {
    this._storage = storage;
    this._subjects = new Map<string, ReplaySubject<unknown>>();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method ngOnDestroy
   * Closes the observables created in the service.
   */
  public ngOnDestroy(): void
  {
    // Close all observables
    this._subjects.forEach(value => value.complete());
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method clear
   * Clear all available keys.
   */
  public clear(): void
  {
    // Closes all subjects
    this._subjects.forEach(value => value.complete());
    // Clear the collection of subjects
    this._subjects.clear();
    // Clear the storage
    this._storage.clear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Protected methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method watch
   * Watch data of given key.
   * @param key > the key to watch.
   */
  protected watch<T>(key: string): Observable<T>
  {
    if (!this._subjects.has(key))
    {
      this._subjects.set(key, new ReplaySubject<T>(1));
    }
    const subject = this._subjects.get(key);
    const item = JSON.parse(this._storage.getItem(key));

    subject.next(item);

    return this._subjects.get(key).asObservable() as Observable<T>;
  }

  /**
   * @method get
   * Get data of given key.
   * @param key > the name of the key to retrieve.
   * @returns the value corresponding to the given key. Can be any type of object.
   */
  protected get<T>(key: string): T
  {
    return JSON.parse(this._storage.getItem(key), dateReviver);
  }

  /**
   * @method has
   * Returns a boolean value indicating if the given key exists in the storage.
   * @param key > the name of the key to check.
   * @returns true if the key exists in the storage, false otherwise.
   */
  protected has(key: string): boolean
  {
    return !isNil(this._storage.getItem(key));
  }

  /**
   * @method set
   * Set value on given key.
   * @param key > the key used to locate the value.
   * @param value > the value to store.
   */
  protected set<T>(key: string, value: T): void
  {
    this._storage.setItem(key, JSON.stringify(value, dateReplacer));
    if (this._subjects.has(key))
    {
      this._subjects.get(key).next(value);
    }
  }

  /**
   * @method remove
   * Remove given key.
   * @param key > the key of the value to remove.
   */
  protected remove(key: string): void
  {
    if (this._subjects.has(key))
    {
      this._subjects.get(key).complete();
      this._subjects.delete(key);
    }
    this._storage.removeItem(key);
  }

}
