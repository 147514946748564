import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from '@progress/kendo-angular-l10n';

@Injectable({providedIn: 'root'})
export class KendoMessageService extends MessageService
{

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  /**
   * @constructor
   * Subscribes to the config service in order to know the default API uri.
   */
  constructor(
    private _translocoService: TranslocoService
  )
  {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method get
   * Gets the currently active language as a string.
   * @returns the name of the language currently used in the application.
   */
  public get language(): string
  {
    return this._translocoService.getActiveLang();
  }
  /**
   * @method set
   * Sets the language of the application.
   * @param value > the identifier of the language to be used in the application.
   */
  public set language(value: string)
  {
    this._translocoService.setActiveLang(value);
    this.notify();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @method get
   * @param key > the key (identifier) of the resource to be translated in the currently active
   * language.
   * @returns the translation of the requested resource.
   */
  public get(key: string): string
  {
    return this._translocoService.translate(key.substring(key.lastIndexOf('.')+1));
  }

}
