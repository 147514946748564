import { InjectionToken } from '@angular/core';

/**
 * List of identifiers used to store predefined values in the local/session/other storages.
 */
export const STORAGE_KEYS =
{
  accessToken            : 'accessToken',
  communication          : 'communication',
  credentials            : 'credentials',
  date                   : 'date',
  language               : 'language',
  officialBodyLogin      : 'officialBodyLogin',
  scheme                 : 'scheme',
  user                   : 'user',
  userContract           : 'userContract',
  webConfiguration       : 'webConfiguration',
  workCenterConfiguration: 'workCenterConfiguration'
};

/**
 * Injection tokens
 */
export const WINDOW = new InjectionToken<object>('WINDOW'); // For injecting the document.window object
