import { inject } from '@angular/core';
import { Navigation, NavigationItem } from 'app/components/navigation';
import { MenuService } from 'app/core/services/menu.service';
import { OfficialBodyLoginService } from 'app/core/services/official-body-login.service';
import { OfficialBodyLogin, WorkCenterConfiguration, WorkCenterConfigurationExternalWeb } from 'app/core/core.types';
//import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { Observable, forkJoin } from 'rxjs';

export const initialDataResolver = (): Observable<[Navigation, NavigationItem[], OfficialBodyLogin, WorkCenterConfigurationExternalWeb, WorkCenterConfiguration]> =>
{
  const menuService = inject(MenuService);
  //const notificationsService = inject(NotificationsService);
  const officialBodyLoginService = inject(OfficialBodyLoginService);

  // Fork join multiple API endpoint calls to wait all of them to finish
  return forkJoin([
    menuService.get(),
    menuService.getUser(),
    //notificationsService.getAll(),
    officialBodyLoginService.get(),
    officialBodyLoginService.getWebConfiguration(),
    officialBodyLoginService.getWorkCenterConfiguration()
  ]);
}
